import Table from "src/components/Table/Table";
import { useEffect, useRef, useState } from "react";
import ContactService from "src/api/services/contact.service.js";
import { MESSAGE_TABLE_COLUMNS } from "src/constants/constants.jsx";
import Modal from "src/components/Modal/Modal.jsx";
import { AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";

function Emails() {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const modalRef = useRef(null);
  const fetchMessages = () => {
    setLoading(true);
    ContactService.getMessages()
      .then((res) => {
        setMessages(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
    modalRef.current.showModal();
  };

  const handleDelete = (id) => {
    ContactService.deleteMessage(id).then(() => {
      toast.success("Message deleted successfully");
      setMessages(messages.filter((message) => message._id !== id));
    });
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <>
      <Table
        columns={MESSAGE_TABLE_COLUMNS}
        data={messages}
        isLoading={loading}
        rowClickable={handleRowClick}
        actions={[
          {
            type: "delete",
            icon: <AiFillDelete className="fill-[red]" />,
            onClick: ({ _id }) => {
              handleDelete(_id);
            },
          },
        ]}
      ></Table>
      <Modal modalRef={modalRef}>
        {selectedRow && (
          <div className="flex flex-col gap-5 items-center justify-center p-5 mt-5 min-h-[10rem] min-w-[10rem]">
            <h1 className="text-xl font-semibold">Message</h1>
            <p className="text-sm text-gray-500">{selectedRow?.message}</p>
          </div>
        )}
      </Modal>
    </>
  );
}

export default Emails;
