import { BsCheckCircle } from "react-icons/bs";

const Completed = () => {
  return (
    <div className="h-full flex items-center justify-center gap-5 flex-col">
      <span className="text-2xl">Completed</span>
      <BsCheckCircle className="text-9xl text-green-500 mx-auto" />
    </div>
  );
};

export default Completed;
