import AppRouter from "src/Router/Router.jsx";
import { AuthProvider } from "src/context/AuthContext.jsx";
import { toast, ToastContainer } from "react-toastify";
import { DataProvider } from "./context/DataContext";
import { Suspense } from "react";
import Loader from "src/components/Loader/Loader.jsx";
import "react-quill/dist/quill.snow.css";

function App() {
  return (
    <div className="app">
      <AuthProvider>
        <DataProvider>
          <Suspense fallback={<Loader />}>
            <AppRouter />
          </Suspense>
          <ToastContainer />
        </DataProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
