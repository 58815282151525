import { createContext, useContext, useState } from "react";
import httpCommon from "src/api/http-common.js";
import {
  getCookieObject,
  setCookieObject,
  setHeadersInCookies,
  signOutUser,
} from "src/utils/helpers/cookies.js";
import AuthService from "src/api/services/auth.service.js";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(getCookieObject("user"));
  const login = async (email, password) => {
    await AuthService.login(email, password).then((res) => {
      const user = res.user;
      setUser(user);
      httpCommon.defaults.headers[
        "Authorization"
      ] = `Bearer ${res.access_token}`;
      setHeadersInCookies(res);
      setCookieObject("user", user);
    });
  };

  const logout = () => {
    // Logic to clear user data on logout
    httpCommon.defaults.headers["Authorization"] = `null`;
    signOutUser();
  };

  const cleanUpAuth = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, cleanUpAuth, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
