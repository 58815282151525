import ReactMapGL, { Marker } from "react-map-gl";
import { useEffect, useRef, useState } from "react";
import { AiFillHome } from "react-icons/ai";
import Modal from "src/components/Modal/Modal.jsx";
import ImageSlider from "src/components/Slider/ImageSlider.jsx";
import { getCentroid } from "src/utils/helpers/actions.js";
import { ReactComponent as AduIcon } from "src/assets/icons/markers/adu.svg";
import { ReactComponent as RemodelIcon } from "src/assets/icons/markers/adition.svg";
import { ReactComponent as PoolIcon } from "src/assets/icons/markers/pool.svg";
import { ReactComponent as BarndIcon } from "src/assets/icons/markers/hotel.svg";
import { ReactComponent as PinIcon } from "src/assets/icons/markers/pin.svg";

const Map = ({
  projects,
  selectedProject,
  setSelectedProject,
  handleShowModal,
}) => {
  const mapRef = useRef(null);
  const adu = "adu";
  const [viewport, setViewport] = useState({
    latitude: "34.064419125296794",
    longitude: "-118.27035609225675",
    width: "100vw",
    height: "500px",
    zoom: 10,
  });
  useEffect(() => {
    selectedProject &&
      setViewport((prev) => ({
        ...prev,
        latitude: selectedProject.latitude,
        longitude: selectedProject.longitude,
        zoom: 10,
      }));
  }, [selectedProject]);

  useEffect(() => {
    if (projects.length === 0) return;
    const center = getCentroid(
      projects.map((project) => ({
        x: project.latitude,
        y: project.longitude,
      })),
    );
    setViewport((prev) => ({
      ...prev,
      latitude: center.x,
      longitude: center.y,
      width: "100vw",
      height: "2000px",
      zoom: 8,
    }));
  }, [projects]);

  return (
    <div className="map-wrapper mb-4 max-w-7xl mx-auto w-full flex">
      <div className="mx-2 w-full h-[500px]">
        <ReactMapGL
          mapboxAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
          viewState={viewport}
          onDrag={(e) => setViewport(e.viewState)}
          onZoom={(e) => setViewport(e.viewState)}
          ref={mapRef}
          mapStyle={import.meta.env.VITE_MAP_STYLE_URL}
        >
          {projects.map((project) => (
            <Marker
              key={project._id}
              longitude={project.longitude}
              latitude={project.latitude}
              onClick={() => {
                setSelectedProject(project);
              }}
            >
              <div
                className={
                  selectedProject && selectedProject._id === project._id
                    ? "text-white rounded-full bg-primary p-1 relative z-50 cursor-default"
                    : "text-white cursor-default z-10"
                }
              >
                <PinIcon className="w-[2rem] h-[2rem] -mt-[1rem] -mr-[1rem]" />
                {/*<img*/}
                {/*  src={"src/assets/icons/markers/pin.png"}*/}
                {/*  className="w-[2rem] h-[2rem]"*/}
                {/*/>*/}
                {/*<img*/}
                {/*  src={"src/assets/icons/markers/pinP.png"}*/}
                {/*  className="w-[8rem] h-[4rem]"*/}
                {/*/>*/}
                {/*{project.name.toLowerCase().includes("adu") ? (*/}
                {/*  <AiFillHome*/}
                {/*    className={*/}
                {/*      selectedProject && selectedProject._id === project._id*/}
                {/*        ? "w-[2rem] h-[2rem]"*/}
                {/*        : "w-[2rem] h-[2rem]"*/}
                {/*    }*/}
                {/*  />*/}
                {/*) : project.name.toLowerCase().includes("remodel") ? (*/}
                {/*  <RemodelIcon*/}
                {/*    className={*/}
                {/*      selectedProject && selectedProject._id === project._id*/}
                {/*        ? "w-[2rem] h-[2rem]"*/}
                {/*        : "w-[2rem] h-[2rem]"*/}
                {/*    }*/}
                {/*  />*/}
                {/*) : project.name.toLowerCase().includes("pool") ? (*/}
                {/*  <PoolIcon*/}
                {/*    className={*/}
                {/*      selectedProject && selectedProject._id === project._id*/}
                {/*        ? "w-[2rem] h-[2rem]"*/}
                {/*        : "w-[2rem] h-[2rem]"*/}
                {/*    }*/}
                {/*  />*/}
                {/*) : project.name.toLowerCase().includes("barnd") ? (*/}
                {/*  <BarndIcon*/}
                {/*    className={*/}
                {/*      selectedProject && selectedProject._id === project._id*/}
                {/*        ? "w-[2rem] h-[2rem]"*/}
                {/*        : "w-[2rem] h-[2rem]"*/}
                {/*    }*/}
                {/*  />*/}
                {/*) : null}*/}
                {selectedProject && selectedProject._id === project._id && (
                  <div className="absolute bottom-full right-full text-secondary rounded-[5px] bg-white p-5 w-[20rem] z-20 cursor-default">
                    <h3 className="text-lg font-bold">{project.name}</h3>
                    <p>{project.title || project.name}</p>
                    <div className="flex justify-between mt-5">
                      <span
                        className="text-primary underline cursor-pointer"
                        onClick={() => {
                          setSelectedProject(null);
                        }}
                      >
                        Close
                      </span>
                      <span
                        className="text-primary underline cursor-pointer"
                        onClick={handleShowModal}
                      >
                        See More
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </Marker>
          ))}
        </ReactMapGL>
      </div>
    </div>
  );
};
export default Map;
