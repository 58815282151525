import Card from "src/components/Cards/ProjectCard.jsx";
import { scroller } from "react-scroll";
import Modal from "src/components/Modal/Modal.jsx";
import ImageSlider from "src/components/Slider/ImageSlider.jsx";
import { useRef } from "react";

const Projects = ({ projects, setSelectedProject, handleShowModal }) => {
  const handleCardClick = (item) => {
    setSelectedProject(item);
    handleShowModal();
    scroller.scrollTo("portfolio", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -100,
    });
  };
  return (
    <div className="projects-wrapper flex flex-col gap-5">
      <div className="flex flex-wrap justify-center gap-5 max-w-7xl mx-auto">
        {projects
          .sort((a, b) => a.index - b.index)
          .map((item) => (
            <Card
              onClick={() => handleCardClick(item)}
              item={item}
              key={item._id}
            />
          ))}
      </div>
    </div>
  );
};

export default Projects;
