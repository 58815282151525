import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { HiEllipsisHorizontal } from "react-icons/hi2";
import { NavLink } from "react-router-dom";
import { useDataContext } from "src/context/DataContext.jsx";
import { transformDate } from "src/utils/helpers/actions.js";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Dashboard = () => {
  const { users, projects } = useDataContext();
  const [recentProjects, setRecentProjects] = useState([]);
  useEffect(() => {
    if (projects.length === 0) return;
    setRecentProjects(
      projects
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 3),
    );
  }, [projects]);

  return (
    <>
      {/* // dash stats */}
      <div>
        <div className="w-[50%] flex flex-wrap justify-center w-full gap-20">
          <div className="p-2">
            <h2 className="text-[2rem] font-bold text-secondary">
              {users?.length - 1 + "+"}
            </h2>
            <p className="text-primary">Clients</p>
          </div>
          <div className="p-2">
            <h2 className="text-[2rem] font-bold text-secondary">
              {users?.filter((user) => user.status == "ACTIVE").length -
                1 +
                "+"}
            </h2>
            <p className="text-primary">Active Clients</p>
          </div>
          <div className="p-2">
            <h2 className="text-[2rem] font-bold text-secondary">
              {projects?.length + "+"}
            </h2>
            <p className="mx-1 text-primary">Projects</p>
          </div>
          <div className="p-2">
            <h2 className="text-[2rem] font-bold text-secondary">
              {projects?.filter((project) => project.status !== "Completed")
                .length + "+"}
            </h2>
            <p className="text-primary">Active Projects</p>
          </div>
        </div>
      </div>

      <main>
        <div className="space-y-16 py-16 xl:space-y-20">
          {/* Recent client list*/}
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="flex items-center justify-between">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Recent Projects
                </h2>
                <NavLink to={"/admin/projects"}>
                  <a
                    href="#"
                    className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                  >
                    View all<span className="sr-only">, projects</span>
                  </a>
                </NavLink>
              </div>
              <ul
                role="list"
                className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
              >
                {recentProjects.map((project) => (
                  <li
                    key={project._id}
                    className="overflow-hidden rounded-xl border border-gray-200"
                  >
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                      <div className="text-sm font-medium leading-6 text-gray-900">
                        {project.name}
                      </div>
                      <Menu as="div" className="relative ml-auto">
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            {/*<Menu.Item>*/}
                            {/*  {({ active }) => (*/}
                            {/*    <a*/}
                            {/*      href="#"*/}
                            {/*      className={classNames(*/}
                            {/*        active ? "bg-gray-50" : "",*/}
                            {/*        "block px-3 py-1 text-sm leading-6 text-gray-900",*/}
                            {/*      )}*/}
                            {/*    >*/}
                            {/*      View*/}
                            {/*      <span className="sr-only">*/}
                            {/*        , {client.name}*/}
                            {/*      </span>*/}
                            {/*    </a>*/}
                            {/*  )}*/}
                            {/*</Menu.Item>*/}
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? "bg-gray-50" : "",
                                    "block px-3 py-1 text-sm leading-6 text-gray-900",
                                  )}
                                >
                                  Edit
                                  <span className="sr-only">
                                    , {project.title || project.name}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Created At</dt>
                        <dd className="text-gray-700">
                          <time dateTime={project.createdAt}>
                            {transformDate(project.createdAt)}
                          </time>
                        </dd>
                      </div>
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Client</dt>
                        <dd className="flex items-start gap-x-2">
                          <div className="font-medium text-gray-900">
                            {project.assign_user.name}
                          </div>
                          <div className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset">
                            {project.status}
                          </div>
                        </dd>
                      </div>
                    </dl>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
