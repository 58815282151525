import { useDataContext } from "src/context/DataContext.jsx";
import { PHASES } from "src/constants/constants.jsx";
import Completed from "src/pages/ClientPortal/Dashboard/Completed/Completed.jsx";
import InputReview from "src/pages/ClientPortal/Dashboard/InputReview/InputReview.jsx";

export const Slider = () => {
  const { projects } = useDataContext();
  const { status, review } = projects[0] || {};

  if (status == undefined) {
    return;
  }

  if (status == "Completed" && (review == undefined || review == "")) {
    return <InputReview />;
  } else if (status == "Completed") {
    return <Completed />;
  }

  return (
    <>
      <h1 className="w-full text-center heading-primary -mt-16">
        Current Status
      </h1>
      <div className="flex sm:flex-row flex-col items-center justify-center gap-10 w-full">
        {PHASES.map((phase, index) => (
          <div
            key={index}
            className={
              "group relative hover:w-[160px] transition-all " +
              (status == phase.key
                ? " opacity-100 w-[150px] h-fit"
                : " opacity-50 w-[100px] h-fit hover:opacity-100")
            }
          >
            <img src={phase.icon} alt="" />
            <div
              className={` z-20 w-[300px] group-hover:hidden sm:group-hover:block hidden absolute ${
                index == 0 ? " -right-[300px] " : " -left-[300px] "
              }  top-1 bg-white p-5 rounded-[8px]`}
            >
              <img src={phase.descIcon} alt="" className="w-[550px] h-fit " />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
