import { useState } from "react";
import { SiWhatsapp } from "react-icons/si";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-scroll";

function Whatsapp() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    description: "",
  });
  const [showForm, setShowForm] = useState(false);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // You can access the form data in formData and perform actions here
  };
  return (
    <>
      {/* {showForm && (
        <div className="w-[20rem] mx-auto justify-between bg-[#f0f2f1] fixed right-5 md:right-20 bottom-10 rounded-md drop-shadow-md ">
          <div className="bg-primary rounded-t-md p-2 flex justify-between">
            <div className="flex items-center">
              <SiWhatsapp className="text-[40px] cursor-pointer text-white" />
              <div className="mx-2">
                <span className="text-white">Talk to Our Friendly Staff</span>
                <p className="text-white">+(92) 307-1234567</p>
              </div>
            </div>
            <AiOutlineClose
              onClick={() => setShowForm(false)}
              className="cursor-pointer text-white"
            />
          </div>

          <div className="w-[95%] mx-auto my-2">
            <form onSubmit={handleSubmit} className="text text-textColor">
              <h1 className="text-[16px] my-2">Schehdule Free Consultation</h1>

              <div className="flex justify-between gap-4 md:gap-[4%] flex-wrap">
                <div className="w-full md:basis-[48%] ">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Name"
                    className="input-primary w-full"
                  />
                </div>
                <div className="w-full md:basis-[48%]">
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    placeholder="Phone"
                    className="input-primary w-full"
                  />
                </div>
              </div>

              <div className="my-4">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  className="w-full input-primary"
                />
              </div>

              <div>
                <label htmlFor="description" className="block">
                  Description:
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="w-full input-primary mb-2"
                />
              </div>
              <button
                type="submit"
                className="bg-secondary text-white px-2 py-1 rounded-md "
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )} */}

        <Link to="contact" spy={true}
        smooth={true}
        duration={500}
>
        <div
          className="w-fit z-20 p-1 flex justify-center items-center gap-5 mx-auto rounded-[15px] text-white fixed bottom-5 right-10 cursor-pointer"
        >
          <SiWhatsapp className="text-[3rem] text-whatsapp drop-shadow-md bg-white p-2 rounded-full" />
        </div>
        </Link>

    </>
  );
}

export default Whatsapp;
