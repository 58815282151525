import { useEffect, useState } from "react";

const FilterBox = ({ publicProjects, setFilteredProjects }) => {
  const [filter, setFilter] = useState("all");
  useEffect(() => {
    if (publicProjects.length === 0) return;
    if (filter === "all") {
      setFilteredProjects(publicProjects);
    } else {
      setFilteredProjects(
        publicProjects.filter((project) => project.type === filter),
      );
    }
  }, [filter, publicProjects]);
  return (
    <div className="flex justify-end items-center gap-5 mx-5">
      <label htmlFor="filter" className="text-secondary text-xl">
        Filter By:
      </label>
      <select
        name="filter"
        id="filter"
        className="border border-secondary rounded-[5px] p-2"
        onChange={(e) => setFilter(e.target.value)}
      >
        <option value="all">Clear Filters</option>
        <option value="ADU">ADU's</option>
        <option value="POOL">Pools</option>
        <option value="BARNDOMINIUMS">Barndominiums</option>
        <option value="REMODELS">Remodels/Additions</option>
      </select>
    </div>
  );
};

export default FilterBox;
