import { useState } from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Review } from "src/components/Review/Review.jsx";

const ImageSlider = ({ item }) => {
  const [current, setCurrent] = useState(0);
  const { images, review, name, description, assign_user } = item;
  const length = images?.length;
  const handleNext = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const handlePrev = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };
  return (
    <div className="slider-wrapper flex flex-col lg:flex-row max-h-[80vh] w-full">
      {!!images?.length !== 0 ? (
        <div className="slider-images relative">
          <button
            className="absolute left-4 z-30 top-1/2 bg-white opacity-80 text-2xl text-center rounded-full w-8 h-8 flex justify-center items-center drop-shadow-md"
            onClick={handlePrev}
          >
            <RiArrowLeftSLine />
          </button>
          {images?.map((image, index) => {
            return (
              <LazyLoadImage
                placeholder={<img src={"assets/images/placeholder.jpg"} />}
                src={image}
                alt="Image Alt"
                className={
                  "h-full w-full max-w-[50rem] max-h-[40rem] " +
                  (index === current ? " block" : " hidden")
                }
                key={index}
              />
            );
          })}
          <div className="dots flex w-full justify-center gap-5 absolute bottom-2  ">
            {images?.map((image, index) => {
              return (
                <div
                  className={
                    (index === current ? "opacity-100" : "opacity-20") +
                    " w-2 h-2 rounded-full bg-white"
                  }
                  key={index}
                  onClick={() => setCurrent(index)}
                />
              );
            })}
          </div>
          <button
            className="absolute right-4
            top-1/2 z-30 bg-white opacity-80 text-2xl text-center rounded-full w-8 h-8 flex justify-center items-center drop-shadow-md
            "
            onClick={handleNext}
          >
            <RiArrowRightSLine />
          </button>
        </div>
      ) : null}
      <div className="slider-description max-w-[25rem] h-full p-5 flex flex-col gap-5">
        <div className="project-title">
          <h1 className="text-2xl font-bold">{name}</h1>
        </div>
        <div className="project-description">
          <p className="text-sm text-textColor">{description}</p>
        </div>
        <hr />
        <Review review={review} user={assign_user} />
        {!!images?.length == 0 && (
          <p className="text-sm text-textColor">No Images Yet</p>
        )}
      </div>
    </div>
  );
};

export default ImageSlider;
