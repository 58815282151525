import httpCommon from "src/api/http-common.js";
import { handleResponse } from "src/utils/helpers/actions.js";

const addProject = (projectData) => {
  return handleResponse(httpCommon.post("/project", projectData));
};

const getProjectById = (projectId) => {
  return handleResponse(httpCommon.get(`/project/${projectId}`));
};
const updateProjectById = (projectId, updatedData) => {
  return handleResponse(httpCommon.patch(`/project/${projectId}`, updatedData));
};

const updateProjectsBulk = (updatedData) => {
  return handleResponse(httpCommon.patch("/project/bulk", updatedData));
};
const getProjects = () => {
  return handleResponse(httpCommon.get("/project"));
};

const getPublicProjects = () => {
  return handleResponse(httpCommon.get("/project/public"));
};

const getProjectsByUserId = (userId) => {
  return handleResponse(
    httpCommon.post(`/project/search`, { assign_to: userId }),
  );
};

const deleteProject = (projectId) => {
  return handleResponse(httpCommon.delete(`/project/${projectId}`));
};

const ProjectService = {
  addProject,
  getProjectById,
  getProjects,
  deleteProject,
  getProjectsByUserId,
  updateProjectById,
  getPublicProjects,
  updateProjectsBulk,
};

export default ProjectService;
