import { Route, Routes, Navigate } from "react-router-dom";
import Home from "src/pages/Home/index.jsx";
import SignIn from "src/pages/SignIn/SignIn.jsx";
import ClientPortal from "src/pages/ClientPortal/index.jsx";
import Projects from "src/pages/ClientPortal/Projects/index.jsx";
import { Projects as AdminProjects } from "src/pages/AdminPanel/Projects/Projects.jsx";
import About from "src/components/About/About";
import Dashboard from "src/pages/ClientPortal/Dashboard/Dashboard.jsx";
import AdminPanel from "src/pages/AdminPanel/index.jsx";
import Users from "src/pages/AdminPanel/Users/Users.jsx";
import Profile from "src/pages/ClientPortal/Profile/Profile.jsx";
import { Dashboard as AdminDashboard } from "src/pages/AdminPanel/Dashboard/Dashboard.jsx";
import ProtectedRoute, {
  AdminProtectedRoute,
} from "src/Router/ProtectedRoutes.jsx";
import { useAuth } from "src/context/AuthContext.jsx";
import Emails from "src/pages/AdminPanel/Emails/Emails";
import Content from "src/pages/AdminPanel/Content/Content.jsx";
import { ProjectFiles as ClientProjectFiles } from "src/pages/ClientPortal/ProjectFiles/ProjectFiles.jsx";
import { ProjectFiles as AdminProjectFiles } from "src/pages/AdminPanel/ProjectFiles/ProjectFiles.jsx";

function AppRouter() {
  const { user } = useAuth();

  return (
    <Routes>
      {/* Define your routes here */}
      <Route exact path="/" element={<Home />} />
      <Route path="signin" element={<SignIn />} />
      <Route
        path="admin"
        element={<AdminProtectedRoute user={user} component={<AdminPanel />} />}
      >
        {/* Define your nested routes here */}
        <Route index element={<AdminDashboard />} />
        <Route path="users" element={<Users />} />
        <Route path="projects" element={<AdminProjects />} />
        <Route path="emails" element={<Emails />} />
        <Route path="projects/:id" element={<AdminProjectFiles />} />
        <Route path="content" element={<Content />} />
        <Route path="profile" element={<Profile />} />
      </Route>

      <Route
        path="portal"
        element={<ProtectedRoute user={user} component={<ClientPortal />} />}
      >
        {/* Define your nested routes here */}
        <Route index element={<Dashboard />} />
        <Route path="profile" element={<Profile />} />
        <Route path="projects" element={<Projects />} />
        <Route path="files" element={<ClientProjectFiles />} />
        <Route path="about" element={<About />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default AppRouter;
