import * as Yup from "yup";
export const UpdateUserSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter user name")
    .required("This field is required"),
  role: Yup.string()
    .required("Please select role")
    .required("This field is required"),
  status: Yup.string()
    .required("Please select status")
    .required("This field is required"),
});

export const UserInitialValues = {
  name: "",
  role: "",
  status: "ACTIVE",
};
