import httpCommon from "src/api/http-common.js";
import { handleResponse } from "src/utils/helpers/actions.js";

const uploadFile = (file, onUploadProgress) => {
  return handleResponse(
    httpCommon.post("/firebase/single", file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    }),
  );
};

const uploadMultipleFiles = (files, onUploadProgress) => {
  return handleResponse(
    httpCommon.post("/firebase/multiple", files, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    }),
  );
};

const deleteFile = (filename) => {
  return handleResponse(
    httpCommon.delete("/firebase/delete", { data: { filename } }),
  );
};

const FirebaseService = {
  uploadFile,
  deleteFile,
  uploadMultipleFiles,
};

export default FirebaseService;
