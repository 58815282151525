import { BiFile, BiImage, BiSolidFilePdf } from "react-icons/bi";
import { Tb360View } from "react-icons/tb";
import { MdCloudDownload } from "react-icons/md";
import downloadFile, { transformDate } from "src/utils/helpers/actions.js";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { AiFillDelete, AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";
import MyPhotoSphereViewer from "src/components/MyPhotoSphereViewer/MyPhotoSphereViewer.jsx";

const File = ({ file, openModal, deleteFile, handlePublished }) => {
  const openImageViewer = () => {
    const content = <img src={file.link} className="h-full w-auto" />;
    openModal(content);
  };

  const open360Viewer = () => {
    const content = <MyPhotoSphereViewer imageUrl={file.link} />;
    openModal(content);
  };

  const viewItem = async (e) => {
    if (file.type === "application/pdf") {
      const content = (
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={[{ uri: file.link }]}
          activeDocument={{ uri: file.link }}
        />
      );
      openModal(content);
    } else if (file.type === "image/jpeg" || file.type === "image/png") {
      openImageViewer();
    } else if (file.type === "image/panorama") {
      open360Viewer();
    } else {
      toast.error("File type not supported");
    }
  };

  return (
    <div className="project-wrapper group">
      <hr className="border-textColor group-hover:border-none opacity-10"></hr>
      <div
        className="file-wrappe w-full grid grid-cols-8 gap-2 text-textColor rounded-[5px] group hover:bg-gray-100 hover:drop-shadow-md hover:rounded-[5px] hover:cursor-pointer border-black group-hover:border-secondary group-hover:border-[1px] p-2"
        onClick={viewItem}
      >
        <div className="flex gap-3 col-span-5 sm:col-span-3 group-hover:text-textColor text-secondary">
          {(() => {
            switch (file.type) {
              case "image/jpeg":
                return <BiImage className="text-2xl grid text-primary" />;
              case "application/pdf":
                return <BiSolidFilePdf className="text-2xl text-secondary" />;
              case "image/panorama":
                return <Tb360View className="text-2xl text-[#000000]" />;
              case "image/jpg":
                return <BiImage className="text-2xl grid text-primary" />;
              case "image/png":
                return <BiImage className="text-2xl grid text-primary" />;
              default:
                return <BiFile className="text-2xl text-gray-500" />;
            }
          })()}
          <div className="file-name text-textColor">{file.name}</div>
        </div>
        <div className="file-type col-span-2">{file.type}</div>
        <div className="file-size hidden sm:block sm:col-span-2 break-words">
          {transformDate(file.upload_date)}
        </div>
        <div className="flex gap-2 items-center">
          <MdCloudDownload
            className="text-2xl col-span-1 place-content-end group-hover:text-textColor text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              downloadFile(file.link, file.name);
            }}
          />
          {deleteFile && (
            <AiFillDelete
              className="text-2xl col-span-1 place-content-end group-hover:text-textColor text-secondary"
              onClick={(e) => {
                e.stopPropagation();
                deleteFile(file);
              }}
            />
          )}
          {handlePublished && (
            <div onClick={(e) => handlePublished(e, file)}>
              {file.isPublished ? (
                <AiFillEye className="text-2xl col-span-1 place-content-end group-hover:text-textColor text-secondary" />
              ) : (
                <AiFillEyeInvisible className="text-2xl col-span-1 place-content-end group-hover:text-textColor text-secondary" />
              )}
            </div>
          )}
        </div>
      </div>
      {/*<hr className="border-textColor group-hover:border-none opacity-10"></hr>*/}
      {/*{isOpen && (*/}
      {/*  <ContextMenu*/}
      {/*    onOpenImageViewer={openImageViewer}*/}
      {/*    onOpenOtherViewer={() => open360Viewer(file.link)}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default File;
