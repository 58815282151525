import httpCommon from "src/api/http-common.js";
import { handleResponse } from "src/utils/helpers/actions.js";

const getContent = () => {
  return handleResponse(httpCommon.get(`/jsonmodule/read`));
};

const updateContent = (content) => {
  return handleResponse(httpCommon.patch(`/jsonmodule`, content));
};

const ContentService = {
  getContent,
  updateContent,
};

export default ContentService;
