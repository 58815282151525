export const FormField = ({
  label,
  type,
  name,
  value,
  onChange,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-textColor"
      >
        {label}
      </label>
      <div className="my-1">
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className="block w-full px-2 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
        />
      </div>
      <div className="text-red-500 text-xs">{error}</div>
    </div>
  );
};

export const TextAreaField = ({
  label,
  type,
  name,
  value,
  onChange,
  error,
  className,
}) => {
  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-textColor"
      >
        {label}
      </label>
      <div className="my-1">
        <div
          onBlur={(e) => {
            onChange({ target: { value: e.target.innerText, name } });
          }}
          className="block w-full h-fit overflow-auto px-2 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
          contentEditable
        >
          {value}
        </div>
      </div>
      <div className="text-red-500 text-xs">{error}</div>
    </div>
  );
};
