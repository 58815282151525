import * as Yup from "yup";

export const ContactSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("Please enter first name")
    .required("This field is required"),
  last_name: Yup.string()
    .required("Please enter last name")
    .required("This field is required"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("This field is required"),
  phone_number: Yup.string()
    .required("Please enter phone number")
    .matches(/^[0-9]+$/, "Please enter a valid phone number")
    .min(10, "Please enter a valid phone number")
    .max(10, "Please enter a valid phone number"),
  message: Yup.string()
    .required("Please enter message")
    .required("This field is required"),
});

export const ContactInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  message: "",
};
