import { useDataContext } from "src/context/DataContext.jsx";
import { useEffect } from "react";
import ProjectService from "src/api/services/project.service.js";
import PinnedProject from "src/components/PinnedProjects/PinnedProject.jsx";
import { Link } from "react-router-dom";
import ContentService from "src/api/services/content.service.js";
import { toast } from "react-toastify";
import { AiFillDelete } from "react-icons/ai";
import FirebaseService from "src/api/services/firebase.service.js";
import RichTextEditor from "src/components/RichTextEditor/RichTextEditor.jsx";

const Content = () => {
  const { publicProjects, setPublicProjects, content, setContent } =
    useDataContext();
  const handleContentUpdate = () => {
    ContentService.updateContent(content).then((res) => {
      toast.success("Content updated successfully");
    });
  };

  const handleFileUpload = async (e) => {
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append("file", file);

    const resp = await toast.promise(FirebaseService.uploadFile(formData), {
      pending: "Uploading file...",
      error: "Failed to upload files",
    });
    if (resp.url) {
      setContent((prev) => ({ ...prev, backgroundImage: resp.url.publicUrl }));
    }
  };

  useEffect(() => {
    if (publicProjects.length === 0) {
      ProjectService.getPublicProjects().then((res) => {
        setPublicProjects(res);
      });
    }
  }, []);

  return (
    <section className="m-5 flex flex-col gap-5">
      <div>
        <h1 className="text-textColor font-semibold text-sm w-fit p-2">
          Background Image URL
        </h1>
        <hr className="my-2" />
        <input
          type="file"
          multiple={true}
          className="block w-fit text-sm text-slate-500
        file:mr-4 file:py-2 file:px-4 file:rounded-md
        file:border-0 file:text-sm file:font-semibold
        file:bg-pink-5 file:btn-primary"
          onChange={handleFileUpload}
          accept="image/png,.pdf,image/jpeg"
        ></input>
      </div>

      <div>
        <h1
          className="text-textColor font-semibold text-sm w-fit p-2"
          contentEditable
          onBlur={(e) => {
            setContent({
              ...content,
              intro: {
                title: e.target.innerText,
                description: content.intro.description,
              },
            });
          }}
        >
          {content?.intro?.title || "Accessories Dwelling Units"}
        </h1>
        <hr className="my-2" />
        <RichTextEditor
          value={content?.intro?.description}
          onChange={(value) => {
            setContent({
              ...content,
              intro: {
                title: content.intro.title,
                description: value,
              },
            });
          }}
        />
      </div>
      <div>
        <h1
          className="text-textColor font-semibold text-sm p-2 w-fit"
          contentEditable
          onBlur={(e) => {
            setContent({
              ...content,
              help: {
                title: e.target.innerText,
                description: content.help.description,
              },
            });
          }}
        >
          {content?.help?.title || "How can we help?"}
        </h1>
        <hr className="my-2" />
        <RichTextEditor
          value={content?.help?.description}
          onChange={(value) => {
            setContent({
              ...content,
              help: {
                title: content.help.title,
                description: value,
              },
            });
          }}
        />
      </div>
      <div>
        <div className="flex items-center justify-between">
          <h1 className="text-textColor font-semibold text-sm">
            Services(Subservices values are comma seperated)
          </h1>
          <button
            className={"btn-primary"}
            onClick={() => {
              setContent({
                ...content,
                services: [
                  ...content?.services,
                  { title: "", subServices: [] },
                ],
              });
            }}
          >
            Add Service
          </button>
        </div>
        <hr className="my-2" />
        <div className="w-full flex flex-col justify-end items-end gap-2">
          <div className="flex gap-2 w-full items-center">
            <span className="w-[25%] input-primary">Title</span>
            <span className="w-[70%] input-primary">Subservices</span>
          </div>

          {content?.services?.map((service) => (
            <>
              <div
                key={service.title}
                className="flex gap-2 w-full items-center"
              >
                <input
                  className="w-[25%] input-primary"
                  onBlur={(e) => {
                    setContent({
                      ...content,
                      services: content.services.map((s) =>
                        s.title === service.title
                          ? { ...s, title: e.target.value }
                          : s,
                      ),
                    });
                  }}
                  defaultValue={service.title}
                ></input>
                <input
                  className="w-[70%] input-primary"
                  defaultValue={service.subServices?.join(",")}
                  onBlur={(e) => {
                    setContent({
                      ...content,
                      services: content.services.map((s) =>
                        s.title === service.title
                          ? { ...s, subServices: e.target.value.split(",") }
                          : s,
                      ),
                    });
                  }}
                />
                <AiFillDelete
                  className={"fill-[red] cursor-pointer"}
                  onClick={() => {
                    setContent({
                      ...content,
                      services: content.services.filter(
                        (s) => s.title !== service.title,
                      ),
                    });
                  }}
                />
              </div>
            </>
          ))}
        </div>
      </div>
      <button className="btn-primary" onClick={handleContentUpdate}>
        Update
      </button>

      <div>
        <h1 className="text-textColor font-semibold text-sm">Projects</h1>
        <hr className="my-2" />
        <div className="flex flex-wrap gap-5">
          {publicProjects.map((project, index) => (
            <Link key={project._id} to={`/admin/projects/${project._id}`}>
              <PinnedProject
                name={project.name}
                description={project.description}
                title={project.title || project.name}
                index={index}
              />
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Content;
