import { useState } from "react";

export const ConfirmAction = ({ onConfirm, icon }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <div className="relative">
      <span
        onClick={(e) => {
          e.stopPropagation();
          setShowConfirm(true);
        }}
      >
        {icon}
      </span>
      {showConfirm && (
        <div
          className="absolute -bottom-full -right-full bg-white rounded-[5px] shadow-xl flex gap-2 p-5 ring-secondary ring-4"
          onMouseLeave={() => setShowConfirm(false)}
        >
          <button
            className="btn-primary bg-red-500 "
            onClick={(e) => {
              e.stopPropagation();
              onConfirm();
              setShowConfirm(false);
            }}
          >
            Confirm
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowConfirm(false);
            }}
            className="btn-primary"
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};
