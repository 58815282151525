import { Link, Outlet, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "src/assets/icons/logo.svg";
import { FaBell } from "react-icons/fa6";
import Tooltip from "src/components/Tooltip/Tooltip";
import { BiMenu, BiSearch } from "react-icons/bi";
import { AiFillSetting, AiOutlineClose } from "react-icons/ai";
import { useEffect, useState } from "react";
import BreadCrumbs from "src/components/BreadCrumbs/BreadCrumbs.jsx";
import { MyPopover } from "src/components/Popover/Popover.jsx";
import { useAuth } from "src/context/AuthContext.jsx";

export function MenuLayout({ menuItems }) {
  const [route, setRoute] = useState("");
  const { user } = useAuth();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const {
    user: { role },
  } = useAuth();

  useEffect(() => {
    setRoute(location.pathname);
    setIsDrawerOpen(false);
  }, [location]);

  return (
    <div className="flex">
      {/* side bar */}
      <div
        className={`w-[10rem] flex flex-col h-screen items-start fixed top-0 left-0 z-40 drop-shadow-md sm:drop-shadow-none bg-gray-50 sm:static sm:translate-x-0 ${
          isDrawerOpen ? "" : " hidden sm:flex"
        }`}
      >
        <div className="h-[8%] sm:h-[12%] flex items-center justify-center">
          <Logo className="w-[8rem] cursor-pointer my-2 p-2 ml-3" />
          <button
            className="absolute top-4 right-1 sm:hidden hover:bg-grey p-1 rounded-[5px]"
            onClick={() => setIsDrawerOpen(false)}
          >
            <AiOutlineClose />
          </button>
        </div>
        <div className="mt-5">
          <ul className="flex flex-col items-start justify-start gap-5 text-textColor">
            {menuItems.map((link) => (
              <Link to={link.route} key={link.name}>
                <li
                  className={`w-[9rem] p-2 pl-0 border-primary cursor-pointer flex items-center hover:bg-white hover:text-primary rounded-r-[10px]  transition-all ${
                    route.split("/")[2] === link.route.split("/")[2] &&
                    "border-l-8 bg-white text-primary shadow-sm"
                  }`}
                >
                  <div className="text-secondary ">{link.icon}</div>
                  <span>{link.name}</span>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
      {/* Searchbar */}
      <div className="w-[98%] h-screen flex flex-col justify-between bg-gray-50">
        <div className="header h-[8%] sm:h-[12%] flex items-center justify-between p-4">
          <BiMenu
            className="text-2xl sm:hidden"
            onClick={() => setIsDrawerOpen(true)}
          />
          <div className="sm:block sm:relative w-[8rem] sm:w-[60%]">
            <div className="pointer-events-none hidden sm:absolute inset-y-0 left-0 sm:flex items-center pl-3">
              <BiSearch className="text-gray-500" />
            </div>
            <input
              type="text"
              placeholder="Search"
              className="w-full py-1 sm:py-3 pl-4 sm:pl-12 pr-4 text-gray-500 border rounded-full outline-none bg-gray-50 focus:bg-white focus:border-indigo-600"
            />
          </div>
          {/* LINKS */}
          <div className="basis-[40%]">
            <ul className="w-full flex items-center justify-end gap-2 sm:gap-5">
              {/*<li>*/}
              {/*  <Link>*/}
              {/*    <Tooltip content="notifications">*/}
              {/*      <FaBell*/}
              {/*        id="fa-bell"*/}
              {/*        className="text-[1.4rem] text-secondary cursor-pointer mx-1"*/}
              {/*      />*/}
              {/*    </Tooltip>*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li>
                <Link
                  to={role == "ADMIN" ? "/admin/profile" : "/portal/profile"}
                >
                  <Tooltip content="settings">
                    <AiFillSetting className="text-[1.4rem] text-secondary mx-1 cursor-pointer" />
                  </Tooltip>
                </Link>
              </li>
              <li>
                <Link>
                  <MyPopover>
                    <img
                      className="inline-block w-8 h-8 rounded-full ring-2 ring-white border-black border-[1px]"
                      src={
                        user?.imageLink ||
                        "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      }
                      alt=""
                    />
                  </MyPopover>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="h-[90%] sm:h-[85%] w-[98%] m-auto bg-white rounded-[5px] overflow-auto no-scrollbar shadow-md">
          <BreadCrumbs />
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default MenuLayout;
