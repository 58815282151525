import { Navigate } from "react-router-dom";
export default function ProtectedRoute({ user, component }) {
  if (user?.role !== "CLIENT") {
    return <Navigate to="/" replace />;
  }
  return component;
}

export function AdminProtectedRoute({ user, component }) {
  if (user?.role !== "ADMIN") {
    return <Navigate to="/" replace />;
  }
  return component;
}
