import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import FirebaseService from "src/api/services/firebase.service";
import ProjectFileService from "src/api/services/projectFile.service";
import { toast } from "react-toastify";
import { extractNameFromUrl } from "src/utils/helpers/actions.js";
import { Files } from "src/components/ProjectDetails/index.jsx";
import { RiLoader2Fill } from "react-icons/ri";

export const ProjectFiles = () => {
  let { id } = useParams();
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploadLoading, setUplaodLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeOfFiles, setTypeOfFiles] = useState("image/jpeg");

  const trackProgress = (progress) => {
    const progressValue = Math.round(progress.progress * 100);
    setProgress(progressValue);
  };

  const handleAddFiles = async (urls, names) => {
    try {
      await urls.map((file, index) =>
        ProjectFileService.addProjectFile({
          name: names[index].name,
          type: typeOfFiles,
          link: file.publicUrl,
          isPublished: false,
          upload_date: new Date().toISOString(),
          project_id: id,
        }),
      );
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  };

  const handleFileUpload = async (e) => {
    setUplaodLoading(true);
    const formData = new FormData();
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      formData.append("files", file);
    });

    const { urls } = await toast.promise(
      FirebaseService.uploadMultipleFiles(formData, trackProgress),
      {
        pending: "Uploading files...",
        error: "Failed to upload files",
      },
    );
    if (urls) {
      await toast.promise(handleAddFiles(urls, files), {
        pending: "Adding files...",
        error: "Failed to add files",
        success: "ProjectFiles added successfully",
      });
      setUplaodLoading(false);
      fetchFiles();
    } else {
      setUplaodLoading(false);
    }
  };

  const handlePublished = (e, file) => {
    e.stopPropagation();
    ProjectFileService.updateProjectFile(file._id, {
      isPublished: !file.isPublished,
    }).then((res) => {
      setFiles((prev) =>
        prev.map((item) => {
          if (item._id === res._id) {
            return { ...item, isPublished: !item.isPublished };
          } else {
            return item;
          }
        }),
      );
    });
  };

  const fetchFiles = () => {
    setLoading(true);
    ProjectFileService.getFiles(id)
      .then((res) => {
        setFiles(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteFile = async (file) => {
    const data = await toast.promise(ProjectFileService.deleteFile(file._id), {
      pending: "Deleting...",
      error: "Failed to delete file",
    });
    if (data) {
      const name = extractNameFromUrl(file.link);
      toast.promise(
        FirebaseService.deleteFile(name).then(() => {
          fetchFiles();
        }),
        {
          pending: "Removing File...",
          error: "Failed to delete file",
          success: "File deleted successfully",
        },
      );
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <div className="projectDetails-wrapper flex flex-col gap-2 relative">
      {loading ? (
        <div className="mt-8 w-full h-[40vh] grid place-items-center">
          <RiLoader2Fill />
        </div>
      ) : (
        <Files
          files={files}
          deleteFile={deleteFile}
          typeOfFiles={typeOfFiles}
          setTypeOfFiles={setTypeOfFiles}
          progress={progress}
          handlePublished={handlePublished}
          handleFileUpload={handleFileUpload}
          handleAddFiles={handleAddFiles}
          uploadLoading={uploadLoading}
        />
      )}
    </div>
  );
};
