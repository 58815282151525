import httpCommon from "src/api/http-common.js";
import { handleResponse } from "src/utils/helpers/actions.js";

const addUser = (data) => {
  return handleResponse(httpCommon.post("/users", data));
};

const getUsers = () => {
  return handleResponse(httpCommon.get("/users"));
};
const getUserById = (id) => {
  return handleResponse(httpCommon.get(`/users/${id}`));
};

const updateUserById = (id, data) => {
  return handleResponse(httpCommon.patch(`/users/${id}`, data));
};

const deleteUserById = (id) => {
  return handleResponse(httpCommon.delete(`/users/${id}`));
};

const UserService = {
  addUser,
  getUsers,
  getUserById,
  updateUserById,
  deleteUserById,
};

export default UserService;
