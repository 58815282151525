import httpCommon from "src/api/http-common.js";
import { handleResponse } from "src/utils/helpers/actions.js";
import { toast } from "react-toastify";

const register = (username, email, password) => {
  return toast.promise(
    handleResponse(
      httpCommon.post("/auth/signup", {
        username,
        email,
        password,
      }),
    ),
    {
      pending: "Creating User...",
    },
  );
};

const login = (email, password) => {
  return toast.promise(
    handleResponse(
      httpCommon.post("/auth/sign-in", {
        email,
        password,
      }),
    ),
    {
      pending: "Logging in...",
    },
  );
};

const AuthService = {
  register,
  login,
};

export default AuthService;
