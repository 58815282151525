import Intro from "./Intro/Intro.jsx";
import Categories from "./Categories/Categories.jsx";
import Navbar from "src/layout/Navbar/Navbar.jsx";
import Whatsapp from "src/components/ContactPopup/Whatsapp.jsx";
import Footer from "src/layout/Footer/Footer.jsx";
import Portfolio from "src/pages/Home/Portfolio/index.jsx";
import Services from "src/pages/Home/Services/index.jsx";
import { useDataContext } from "src/context/DataContext.jsx";

const Home = () => {
  const { content } = useDataContext();
  return (
    <div className="home-wrapper flex flex-col relative">
      <Navbar />
      <div className="-z-50 absolute top-0 left-0 w-full h-full bg-black opacity-90">
        <img
          src={content.backgroundImage}
          className="w-full h-[100vh] object-cover sticky top-0 left-0"
        />
      </div>
      <div className="-z-50 absolute top-0 left-0 w-full h-full">
        <div
          className="w-full h-[100vh] object-cover top-0 left-0 sticky z-20"
          style={{
            background: `linear-gradient(0deg, #18479B  20%, transparent 70%)`,
          }}
        ></div>
      </div>

      <section id="home" className="flex flex-col gap-20 pb-10">
        <Intro />
        <Categories />
      </section>

      <section id="portfolio" className="mt-20">
        <Portfolio />
      </section>

      <section id="services" className="mt-20 bg-white">
        <Services />
      </section>

      <Whatsapp />
      <Footer className="mt-20" />
    </div>
  );
};

export default Home;
