import Status from "src/components/ProjectDetails/Status/Status.jsx";
import { useRef, useState } from "react";
import File from "src/components/ProjectDetails/File/File.jsx";
import { Tab } from "@headlessui/react";
import Modal from "src/components/Modal/Modal.jsx";

export const Files = ({
  files,
  handleFileUpload,
  uploadLoading,
  handlePublished,
  deleteFile,
  typeOfFiles,
  setTypeOfFiles,
  progress,
}) => {
  const modalRef = useRef(null);
  const [dialogContent, setDialogContent] = useState(null);

  const openModal = (content) => {
    setDialogContent(content);
    modalRef.current.showModal();
  };

  return (
    <div className="projectDetails-wrapper flex flex-col gap-2 relative">
      <div className="flex ml-3 gap-2 items-center">
        {handleFileUpload && (
          <>
            <select
              onChange={(e) => setTypeOfFiles(e.target.value)}
              value={typeOfFiles}
              className="mr-4 p-2 rounded-md border-2 "
            >
              <option value="image/jpeg">Image</option>
              <option value="application/pdf">Pdf</option>
              <option value="image/panorama">Panorama</option>
            </select>

            <input
              type="file"
              multiple={true}
              className="block w-fit text-sm text-slate-500
        file:mr-4 file:py-2 file:px-4 file:rounded-md
        file:border-0 file:text-sm file:font-semibold
        file:bg-pink-5 file:btn-primary"
              onChange={handleFileUpload}
              accept="image/png,.pdf,image/jpeg"
            ></input>
          </>
        )}
        {progress > 0 && progress <= 100 && uploadLoading && (
          <div className="relative w-1/3 bg-gray-200 rounded-full h-1">
            <div
              id="progressBar"
              className={`absolute top-0 bg-secondary h-1 rounded-full`}
              style={{ width: `${progress}%` }}
            >
              {progress}%
            </div>
          </div>
        )}
      </div>

      {/*<Tab.Group>*/}
      {/*  <Tab.List className="flex border-b-[1px]">*/}
      {/*    <Tab className="px-4 pt-4 outline-none">*/}
      {/*      {({ selected }) => (*/}
      {/*        <div*/}
      {/*          className={*/}
      {/*            selected*/}
      {/*              ? "text-primary decoration-secondary underline"*/}
      {/*              : "text-textColor"*/}
      {/*          }*/}
      {/*        >*/}
      {/*          <span>Files</span>*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    </Tab>*/}
      {/*    <Tab className="px-4 pt-4 outline-none">*/}
      {/*      {({ selected }) => (*/}
      {/*        <div*/}
      {/*          className={*/}
      {/*            selected*/}
      {/*              ? "text-primary decoration-secondary underline"*/}
      {/*              : "text-textColor"*/}
      {/*          }*/}
      {/*        >*/}
      {/*          <span>Status</span>*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    </Tab>*/}
      {/*  </Tab.List>*/}
      {/*  <Tab.Panels>*/}
      {/*    <Tab.Panel>*/}
      <div>
        <div className="file-wrappe w-full grid grid-cols-8 sticky top-0 bg-white drop-shadow-sm p-5 z-[2] text-textColor">
          <p className="col-span-5 sm:col-span-3">Name</p>
          <p className="col-span-2">Type</p>
          <p className="hidden sm:block sm:col-span-2">Date</p>
          <p className="col-span-1">Actions</p>
        </div>
        <div className="flex flex-col gap-5 justify-between p-5">
          {files.length === 0 ? (
            <div className="mt-8 w-full h-[40vh] grid place-items-center">
              No Data
            </div>
          ) : (
            files.map((item) => (
              <File
                file={item}
                key={item.id}
                openModal={openModal}
                handlePublished={handlePublished ? handlePublished : null}
                deleteFile={deleteFile ? deleteFile : null}
              />
            ))
          )}
        </div>
      </div>
      {/*    </Tab.Panel>*/}
      {/*    <Tab.Panel className="m-auto w-full flex justify-center items-center mt-5">*/}
      {/*      <Status />*/}
      {/*    </Tab.Panel>*/}
      {/*  </Tab.Panels>*/}
      {/*</Tab.Group>*/}
      <Modal
        modalRef={modalRef}
        clearModal={() => setDialogContent(null)}
        className="h-full w-full"
      >
        {dialogContent}
      </Modal>
    </div>
  );
};
