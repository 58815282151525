import { RiDashboardFill } from "react-icons/ri";
import { FaBarsProgress } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { transformDate } from "src/utils/helpers/actions";
import { MdEmail } from "react-icons/md";
import Phase1Icon from "src/assets/icons/dashboardIcons/p1.svg";
import Phase2Icon from "src/assets/icons/dashboardIcons/p2.svg";
import Phase3Icon from "src/assets/icons/dashboardIcons/p3.svg";
import Phase4Icon from "src/assets/icons/dashboardIcons/p4.svg";
import Phase5Icon from "src/assets/icons/dashboardIcons/p5.svg";
import Phase1DescIcon from "src/assets/icons/dashboardIcons/p1Details.svg";
import Phase2DescIcon from "src/assets/icons/dashboardIcons/p2Details.svg";
import Phase3DescIcon from "src/assets/icons/dashboardIcons/p3Details.svg";
import Phase4DescIcon from "src/assets/icons/dashboardIcons/p4Details.svg";
import Phase5DescIcon from "src/assets/icons/dashboardIcons/p5Details.svg";
import { BiSolidBookContent } from "react-icons/bi";
export const ADU_DEFINITION =
  "Accessory Dwelling Units (ADUs) are secondary residential structures or units located on the same property as a primary dwelling, often used for additional living space, rental income, or housing family members.";
export const HOW_WE_HELP =
  "We help you with the entire process of building an ADU, from design to construction. We also help you with financing and permitting.";

export const PROJECTS = [
  {
    id: 1,
    latitude: 45.3811,
    longitude: -75.5903,
    name: "League",
    title: "League",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    images: [
      "/assets/images/League/ADU League -Test 1 (1).jpg",
      "/assets/images/League/ADU League -Test 1 (2).jpg",
      "/assets/images/League/ADU League -Test 1 (3).jpg",
      "/assets/images/League/ADU League -Test 1 (4).jpg",
    ],
    review: {
      name: "League",
      rating: 5,
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  },
  // {
  //   name:"Garbel",
  //   title:"Garbel",
  //   description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   images:[
  //       "/assets/images/Garbel/Pool Garbel  - HQ (1).jpg",
  //       "/assets/images/Garbel/Pool Garbel  - HQ (2).jpg",
  //       "/assets/images/Garbel/Pool Garbel  - HQ (3).jpg",
  //     "/assets/images/Garbel/Pool Garbel  - HQ (4).jpg",
  //     "/assets/images/Garbel/Pool Garbel  - HQ (5).jpg",
  //       ],    review:{
  //     name:"League",
  //     rating:5,
  //     comment:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  //   }
  //
  // },
  // {
  //   "name": "Gaviota",
  //   "title": "Gaviota",
  //   "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   "images": [
  //       "/assets/images/Gaviota/ADU Gaviota - HQ  (1).jpg",
  //       "/assets/images/Gaviota/ADU Gaviota - HQ  (2).jpg",
  //       "/assets/images/Gaviota/ADU Gaviota - HQ  (3).jpg",
  //       "/assets/images/Gaviota/ADU Gaviota - HQ  (4).jpg",
  //       "/assets/images/Gaviota/ADU Gaviota - HQ  (5).jpg"] ,   review:{
  //     name:"League",
  //     rating:5,
  //     comment:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  //   }
  //
  // },
  // {
  //   "name": "Heatherwood Pool",
  //   "title": "Heatherwood Pool",
  //   "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   "images": [
  //       "/assets/images/Heatherwood Pool/Heatherwood - HQ 01.jpg",
  //       "/assets/images/Heatherwood Pool/Heatherwood - HQ 02.jpg",
  //       "/assets/images/Heatherwood Pool/Heatherwood - HQ 03.jpg",
  //       "/assets/images/Heatherwood Pool/Heatherwood - HQ 04.jpg",
  //       "/assets/images/Heatherwood Pool/Heatherwood - HQ 05.jpg"]  ,  review:{
  //     name:"League",
  //     rating:5,
  //     comment:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  //   }
  //
  // },
  {
    id: 2,
    latitude: 45.4811,
    longitude: -75.6903,
    name: "Lincoln",
    title: "Lincoln",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    images: [
      "/assets/images/Lincoln/Lincoln 1 - Test 1.jpg",
      "/assets/images/Lincoln/Lincoln 2 - Test 1.jpg",
      "/assets/images/Lincoln/Lincoln 3 - Test 1.jpg",
    ],
    review: {
      name: "League",
      rating: 5,
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  },
  {
    id: 3,
    latitude: 45.4511,
    longitude: -75.7903,
    name: "Radnor ADU",
    title: "Radnor ADU",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    images: [
      "/assets/images/Radnor ADU/ADU Radnor_ 3 - Test 1.jpg",
      "/assets/images/Radnor ADU/ADU Radnor_ 3 - Test 2.jpg",
      "/assets/images/Radnor ADU/ADU Radnor_ 3 - Test 3.jpg",
      "/assets/images/Radnor ADU/ADU Radnor_ 3 - Test 4.jpg",
    ],
    review: {
      name: "League",
      rating: 5,
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  },
];

export const SERVICES = [
  {
    title: "Services",
    services: [
      "Garage Conversion",
      "ADUs/JADU",
      "Additions",
      "Remodels",
      "Pools",
      "Barndominiums",
    ],
  },
  {
    title: "BIM Consulting",
    services: [
      "Implementation",
      "Training, Support & Mentoring",
      "Content Creation",
      "Virtual Design and Construction (VDC) Coordination & Management",
      "Clash Avoidance",
      "3D Visualization",
      "Quantification Services",
    ],
  },
  {
    title: "Aerial Services",
    services: ["Images & Video", "Aerial Mapping", "Photogrammetry"],
  },
];

export const PROJECTS_IMAGES = [
  {
    id: 1,
    images: [
      "/assets/images/League/ADU League -Test 1 (1).jpg",
      "/assets/images/League/ADU League -Test 1 (4).jpg",
    ],
  },
  {
    id: 2,
    images: [
      "/assets/images/Lincoln/Lincoln 1 - Test 1.jpg",
      "/assets/images/Lincoln/Lincoln 3 - Test 1.jpg",
    ],
  },
  {
    id: 3,
    images: [
      "/assets/images/Lincoln/Lincoln 1 - Test 1.jpg",
      "/assets/images/Lincoln/Lincoln 3 - Test 1.jpg",
    ],
  },
  {
    id: 4,
    images: [
      "/assets/images/Lincoln/Lincoln 1 - Test 1.jpg",
      "/assets/images/Lincoln/Lincoln 3 - Test 1.jpg",
    ],
  },
  {
    id: 5,
    images: [
      "/assets/images/Lincoln/Lincoln 1 - Test 1.jpg",
      "/assets/images/Lincoln/Lincoln 3 - Test 1.jpg",
    ],
  },
  {
    id: 6,
    images: [
      "/assets/images/Lincoln/Lincoln 1 - Test 1.jpg",
      "/assets/images/Lincoln/Lincoln 3 - Test 1.jpg",
    ],
  },
  {
    id: 7,
    images: [
      "/assets/images/Lincoln/Lincoln 1 - Test 1.jpg",
      "/assets/images/Lincoln/Lincoln 3 - Test 1.jpg",
    ],
  },
  {
    id: 8,
    images: [
      "/assets/images/Lincoln/Lincoln 1 - Test 1.jpg",
      "/assets/images/Lincoln/Lincoln 3 - Test 1.jpg",
    ],
  },
  {
    id: 9,
    images: [
      "/assets/images/Lincoln/Lincoln 1 - Test 1.jpg",
      "/assets/images/Lincoln/Lincoln 3 - Test 1.jpg",
    ],
  },
  {
    id: 10,
    images: [
      "/assets/images/Lincoln/Lincoln 1 - Test 1.jpg",
      "/assets/images/Lincoln/Lincoln 3 - Test 1.jpg",
    ],
  },
  {
    id: 11,
    images: [
      "/assets/images/Lincoln/Lincoln 1 - Test 1.jpg",
      "/assets/images/Lincoln/Lincoln 3 - Test 1.jpg",
    ],
  },
];

export const PROCESS_INFO = [
  {
    no: "1",
    name: "Step 1",
    image: "/assets/images/ADUT1.png",
    description: "This resource is busy at the moment",
  },
  {
    no: "2",
    name: "Step 2",
    image: "/assets/images/ADUT2.png",
    description: "This resource is busy at the moment",
  },
  {
    no: "3",
    name: "Step 3",
    image: "/assets/images/ADUT3.png",
    description: "This resource is busy at the moment",
  },
  {
    no: "4",
    name: "Step 4",
    image: "/assets/images/ADUT4.png",
    description: "This resource is busy at the moment",
  },
];

export const STATUS_STEPS = [
  {
    name: "Field Survey",
    value: "Field_Survey",
    description: "Starting the project",
    href: "#",
    status: "complete",
  },
  {
    name: "Digitize Phase",
    value: "Digitize_Phase",
    description: "Working on the project",
    href: "#",
    status: "current",
  },
  {
    name: "Design Concepts",
    value: "Design_Concepts",
    description: "Completed the project",
    href: "#",
    status: "upcoming",
  },
  {
    name: "Construction Documents",
    value: "Construction_Documents",
    description: "Completed the project",
    href: "#",
    status: "upcoming",
  },
  {
    name: "City Submission",
    value: "City_Submission",
    description: "Completed the project",
    href: "#",
    status: "upcoming",
  },
  {
    name: "Completed",
    value: "Completed",
    description: "Completed the project",
    href: "#",
    status: "upcoming",
  },
];

// eslint-disable-next-line react-refresh/only-export-components
export const CLIENT_PORTAL_ROUTES = [
  {
    name: "Dashboard",
    route: "/portal",
    icon: <RiDashboardFill className="text-[1.4rem]  mx-2 cursor-pointer" />,
  },
  // {
  //   name: "Projects",
  //   route: "/portal/projects",
  //   icon: <FaBarsProgress className="text-[1.4rem] mx-2 cursor-pointer" />,
  // },
  {
    name: "Files",
    route: "/portal/files",
    icon: <FaBarsProgress className="text-[1.4rem] mx-2 cursor-pointer" />,
  },
  {
    name: "Profile",
    route: "/portal/profile",
    icon: <CgProfile className="text-[1.4rem]  mx-2 cursor-pointer" />,
  },
  // {
  //   name: "About",
  //   route: "/portal/about",
  //   icon: (
  //     <BsFillInfoCircleFill className="text-[1.4rem]  mx-2 cursor-pointer" />
  //   ),
  // },
];

// eslint-disable-next-line react-refresh/only-export-components
export const ADMIN_ROUTES = [
  {
    name: "Dashboard",
    route: "/admin",
    icon: <RiDashboardFill className="text-[1.4rem]  mx-2 cursor-pointer" />,
  },
  {
    name: "Users",
    route: "/admin/users",
    icon: <FaUsers className="text-[1.4rem] mx-2 cursor-pointer" />,
  },
  {
    name: "Projects",
    route: "/admin/projects",
    icon: <FaBarsProgress className="text-[1.4rem]  mx-2 cursor-pointer" />,
  },
  {
    name: "Content",
    route: "/admin/content",
    icon: <BiSolidBookContent className="text-[1.4rem]  mx-2 cursor-pointer" />,
  },
  {
    name: "Emails",
    route: "/admin/emails",
    icon: <MdEmail className="text-[1.4rem]  mx-2 cursor-pointer" />,
  },
  {
    name: "Profile",
    route: "/admin/profile",
    icon: <CgProfile className="text-[1.4rem]  mx-2 cursor-pointer" />,
  },
];

export const PROJECTS_DATA = [
  {
    id: 1,
    name: "Project 1",
    date: "2021-09-01",
  },
  {
    id: 2,
    name: "Project 2",
    date: "2021-09-01",
  },
  {
    id: 3,
    name: "Project 3",
    date: "2021-09-01",
  },
];

export const USERS_TABLE_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

export const USERS_TABLE_DATA = [
  {
    id: 1,
    name: "User 1",
    email: "",
    phone: "",
    role: "Admin",
    status: "Active",
  },
  {
    id: 2,
    name: "User 2",
    email: "",
    phone: "",
    role: "Admin",
    status: "Active",
  },
  {
    id: 3,
    name: "User 3",
    email: "",
    phone: "",
    role: "Admin",
    status: "Active",
  },
  {
    id: 4,
    name: "User 4",
    email: "",
    phone: "",
    role: "Admin",
    status: "Active",
  },
];

export const PROJECTS_TABLE_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Client",
    accessor: "assign_user",
  },
  // {
  //   Header: "User",
  //   accessor: "user",
  // },
  // {
  //   Header: "Start Date",
  //   accessor: "start_date",
  //   transform: transformDate,
  // },
  // {
  //   Header: "Estimated End Date",
  //   accessor: "estimated_end_date",
  //   transform: transformDate,
  // },
  {
    Header: "Status",
    accessor: "status",
  },

  {
    Header: "Access",
    accessor: "access",
  },
];

export const MESSAGE_TABLE_COLUMNS = [
  {
    Header: "First Name",
    accessor: "first_name",
  },
  {
    Header: "Last Name",
    accessor: "last_name",
  },
  {
    Header: "Email",
    accessor: "email",
    maxWidth: "5rem",
  },
  {
    Header: "Message",
    accessor: "message",
  },
  {
    Header: "Date",
    accessor: "createdAt",
    transform: transformDate,
  },
];

export const PROJECTS_TABLE_DATA = [
  {
    id: 1,
    name: "Project 1",
    date: "2021-09-01",
    user: "User 1",
  },
  {
    id: 2,
    name: "Project 2",
    date: "2021-09-01",
    user: "User 2",
  },
];

export const PHASES = [
  {
    index: 0,
    key: "Field_Survey",
    icon: Phase1Icon,
    descIcon: Phase1DescIcon,
  },
  {
    index: 1,
    key: "Digitize_Phase",
    icon: Phase2Icon,
    descIcon: Phase2DescIcon,
  },
  {
    index: 2,
    key: "Design_Concepts",
    icon: Phase3Icon,
    descIcon: Phase3DescIcon,
  },
  {
    index: 3,
    key: "Construction_Documents",
    icon: Phase4Icon,
    descIcon: Phase4DescIcon,
  },
  {
    index: 4,
    key: "City_Submission",
    icon: Phase5Icon,
    descIcon: Phase5DescIcon,
  },
];
