import { MenuLayout } from "src/layout/MenuLayout/MenuLayout.jsx";
import { ADMIN_ROUTES } from "src/constants/constants.jsx";
import { Suspense, useEffect, useState } from "react";
import UserServices from "src/api/services/user.service.js";
import ProjectService from "src/api/services/project.service.js";
import { useDataContext } from "src/context/DataContext.jsx";
import { RiLoader2Fill } from "react-icons/ri";
import Loader from "src/components/Loader/Loader.jsx";

const AdminPanel = () => {
  const { projects, users, setUsers, setProjects } = useDataContext();
  const [loading, setLoading] = useState(true);

  const fetchProjects = async () => {
    if (projects.length === 0) {
      await ProjectService.getProjects().then((res) => {
        setProjects(res);
      });
    }
  };

  const fetchUsers = async () => {
    if (users.length === 0) {
      let users = await UserServices.getUsers();
      setUsers(users);
      return true;
    }
    return false;
  };

  const fetchData = async () => {
    setLoading(true);
    let isUsers = await fetchUsers();
    if (isUsers) {
      await fetchProjects();
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // Fetch data only if users are empty

  return (
    <div className="clientPortal-wrapper">
      {loading ? <Loader /> : <MenuLayout menuItems={ADMIN_ROUTES} />}
    </div>
  );
};

export default AdminPanel;
