import { BsFacebook, BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { Contact } from "./Contact/Contact";
const Footer = () => {
  const FOOTER_DATA = {
    company: [
      {
        name: "About",
        url: "#",
      },
      {
        name: "Blog",
        url: "#",
      },
      {
        name: "Jobs",
        url: "#",
      },
      {
        name: "Press",
        url: "#",
      },
    ],
    legal: [
      {
        name: "Claim",
        url: "#",
      },
      {
        name: "Privacy",
        url: "#",
      },
      {
        name: "Terms",
        url: "#",
      },
    ],
  };
  return (
    <footer
      className="bg-textColor mt-[5rem] sm:mt-[20rem] "
      aria-labelledby="footer-heading"
    >
      <section className="max-w-7xl mx-auto">
        {/* // left section */}
        <div className="flex flex-col-reverse sm:flex-row justify-between items-center border-b p-1">
          <div className="text-center">
            <h1 className="text-[2rem] text-secondary ">
              Let's keep in touch!
            </h1>
            <p className="text-paragraph text-white">
              Find us on any of these platforms, we respond 1-2 business days.
            </p>
            <div className="flex w-full my-4 px-28  space-x-6 md:order-2">
              <div className="rounded-full">
                <a href="#" className=" ">
                  <span className="sr-only">Facebook</span>
                  <BsFacebook className="text-2xl text-white" />
                </a>
              </div>
              <div className="rouded-full ">
                <a href="#" className="text-white ">
                  <span className="sr-only">Instagram</span>
                  <BsInstagram className="text-2xl" />
                </a>
              </div>
              <div className="rounded-full">
                <a href="#" className="text-white ">
                  <span className="sr-only">Twitter</span>
                  <BsTwitter className="text-2xl" />
                </a>
              </div>
              <div className="rounded-full">
                <a href="#" className="text-white ">
                  <span className="sr-only">YouTube</span>
                  <BsYoutube className="text-2xl" />
                </a>
              </div>
            </div>
          </div>
          <Contact />
        </div>
      </section>
      <div className="flex items-center justify-center ">
        <p className="p-2 text-center text-white  ">Copyright © 2023</p>
      </div>
    </footer>
  );
};

export default Footer;
