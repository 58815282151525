import ReactMapGL, { Marker } from "react-map-gl";
import MapService from "src/api/services/map.service.js";
import { useState } from "react";

const MapDirectory = ({ values, setValues }) => {
  const [viewport, setViewport] = useState({
    latitude: "34.064419125296794",
    longitude: "-118.27035609225675",
    width: "100vw",
    height: "500px",
    zoom: 10,
  });

  const handleMapClick = async (e) => {
    const { features } = await MapService.getAddress(
      e.lngLat.lng,
      e.lngLat.lat,
    );
    setValues({ latitude: e.lngLat.lat, longitude: e.lngLat.lng }, features);
  };
  return (
    <div className="map-wrapper w-[400px] h-[300px]">
      <ReactMapGL
        style={{ width: "100%", height: "100%" }}
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
        mapStyle={import.meta.env.VITE_MAP_STYLE_URL}
        onClick={handleMapClick}
        onDrag={(e) => setViewport(e.viewState)}
        onZoom={(e) => setViewport(e.viewState)}
        viewState={viewport}
      >
        <Marker longitude={values?.longitude} latitude={values?.latitude} />
      </ReactMapGL>
    </div>
  );
};

export default MapDirectory;
