import { Link } from "react-router-dom";
import { useDataContext } from "src/context/DataContext.jsx";
import { useEffect, useState } from "react";
import ProjectService from "src/api/services/project.service";
import { useAuth } from "src/context/AuthContext";
import { Slider } from "src/pages/ClientPortal/Dashboard/Slider/Slider.jsx";
import Completed from "src/pages/ClientPortal/Dashboard/Completed/Completed.jsx";

const Dashboard = () => {
  const { projects, setProjects } = useDataContext();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (projects.length === 0) {
      setLoading(true);
      ProjectService.getProjectsByUserId(user._id)
        .then((res) => {
          setProjects(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <div
      className="dashboard-wrapper p-5 flex flex-col gap-10 w-full h-full"
      style={{
        background: "url('src/assets/icons/dashboardIcons/bg.svg')",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/*<section className="project">*/}
      {/*  <h2 className="text-sm font-medium text-gray-500">Pinned Projects</h2>*/}
      {/*  <ul*/}
      {/*    role="list"*/}
      {/*    className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"*/}
      {/*  >*/}
      {/*      {*/}
      {/*        loading ?  <div className="mt-8 w-full h-[40vh] grid place-items-center">*/}
      {/*        <RiLoader2Fill />*/}
      {/*      </div>*/}
      {/*      :*/}
      {/*        projects?.length === 0 ? <p className={"text-textColor"}>No Pinned Projects Found</p> :*/}
      {/*          projects.map((project, index) => (*/}
      {/*            <Link to={`/portal/projects/${project._id}`} key={project._id}>*/}
      {/*              <PinnedProject*/}
      {/*                key={project._id}*/}
      {/*                name={project.name}*/}
      {/*                description={project.name}*/}
      {/*                index={index}*/}
      {/*              />*/}
      {/*            </Link>*/}
      {/*          )*/}
      {/*        )*/}
      {/*      }*/}
      {/*  </ul>*/}
      {/*</section>*/}
      {/* <section className="stats">
        <h2 className="text-sm font-medium text-gray-500">Stats</h2>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {[
            { title: "Total CLients", numbers: "6533" },
            { title: "Total Projects", numbers: "12332" },
          ].map((stat, index) => (
            <Stats title={stat.title} numbers={stat.numbers} key={index} />
          ))}
        </dl>
      </section> */}
      {projects && <Slider />}
      <Link to="/"></Link>
    </div>
  );
};

export default Dashboard;
