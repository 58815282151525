import PropTypes from "prop-types";

const Card = ({ imageSrc, title, index }) => {
  return (
    <div className="categoryCard-wrapper w-full col-span-2">
      <div className={`flex  ${index % 2 == 0 ? "" : "flex-row-reverse"}`}>
        <div
          className={`flex items-center justify-between px-2 bg-white h-[8rem] shadow-md ${
            index % 2 == 0 ? "border-l-8" : "border-r-8"
          } border-secondary w-full`}
        >
          <h2 className="font-bold text-center text-primary">{title}</h2>
          <img src={imageSrc} alt="category image" className="w-[10rem]" />
        </div>
        <div
          className={`border-l-[4rem] border-l-white border-b-[8rem] border-b-transparent ${
            index % 2 == 0
              ? ""
              : "rotate-180 shadow-[0px_-15px_10px_-15px_rgba(0,0,0,0.3)]"
          }`}
        ></div>
      </div>
    </div>
  );
};
export default Card;

Card.propTypes = {
  imageSrc: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
