import httpCommon from "src/api/http-common.js";
import { handleResponse } from "src/utils/helpers/actions.js";

export const getAddress = (lat, lng) => {
  return handleResponse(
    httpCommon.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lat},${lng}.json?types=address&access_token=${
        import.meta.env.VITE_MAPBOX_TOKEN
      }`,
    ),
  );
};

const MapService = {
  getAddress,
};

export default MapService;
