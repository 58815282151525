import ReactQuill, { Quill } from "react-quill";

const Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);

const App = ({ value, onChange }) => {
  const module = {
    toolbar: [{ color: [] }, "bold", "italic", "underline"],
  };

  const formats = ["bold", "italic", "underline", "color", "align"];

  return (
    <ReactQuill
      placeholder="Write your content here..."
      onChange={onChange}
      value={value}
      modules={module}
      formats={formats}
    />
  );
};

export default App;
