import cookie from "react-cookies";
import CryptoJS from "crypto-js";
export const setCookie = (name, value, options = { path: "/" }) => {
  cookie.save(name, value, options);
};

export const encodeData = (data) => {
  try {
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      import.meta.env.VITE_SECRET_KEY,
    ).toString();
    return ciphertext;
  } catch (e) {
    return null;
  }
};

// Decoder: Decrypts the data
export const decodeData = (ciphertext) => {
  try {
    const bytes = CryptoJS.AES.decrypt(
      ciphertext,
      import.meta.env.VITE_SECRET_KEY,
    );
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (e) {
    return null;
  }
};

export const setCookieObject = (name, obj, options = { path: "/" }) => {
  const encoded = encodeData(obj);
  cookie.save(name, encoded, options);
};

export const setHeadersInCookies = (apiResponse) => {
  const encodedData = encodeData(apiResponse.access_token);
  setCookie("access_token", encodedData);
  // setCookieObject("user", apiResponse.user);
};

export const removeCookie = (name, options = { path: "/" }) => {
  cookie.remove(name, options);
};

export const getCookieObject = (name, type) => {
  const val = cookie.load(name);
  return decodeData(val) || "";
};

export const getCookie = (name) => {
  const val = cookie.load(name);
  return decodeData(val) || "";
};

export const signOutUser = () => {
  removeCookie("access_token");
  removeCookie("user");
};

export const ValidateEmail = (mail) => {
  if (
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(myForm.emailAddr.value)
  ) {
    return true;
  }
  alert("You have entered an invalid email address!");
  return false;
};
