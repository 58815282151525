import { AiFillHome } from "react-icons/ai";
import {useLocation, useNavigate} from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";

const BreadCrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) =>   crumb !== "");
  return (
    <nav className="flex p-5" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <AiFillHome
                className="flex-shrink-0 h-5 w-5"
                aria-hidden="true"
                onClick={
                  ()=>{
                    navigate("/"+crumbs[0]);
                  }
                }
              />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {crumbs.map((crumb, index) => (
          <li key={index}>
            <div className="flex items-center">
              <BiChevronRight className="text-2xl text-gray-400" />
              <a
                href="#"
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 capitalize"
                aria-current="page"
                onClick={
                  ()=>{
                    navigate("/"+crumbs.slice(0,index+1).join("/"));
                  }
                }
              >
                {crumb}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
