import PropTypes from "prop-types";
import Card from "src/components/Cards/ProjectCard.jsx";

export const Review = ({ review, user }) => {
  // const { rating, comment, name } = review || {};
  const { name } = user || {};

  if (!review || review.length === 0) {
    return (
      <div className="project-description">
        <p className="text-sm text-textColor">No reviews Yet</p>
      </div>
    );
  }

  return (
    <div className="client-review">
      <div className="flex items-center">
        {/*<img*/}
        {/*  src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
        {/*  alt="avatar"*/}
        {/*  className="w-14 h-14 rounded-full mr-4"*/}
        {/*/>*/}
        <div className="flex-1">
          <h3 className="font-bold">{name}</h3>
          {/*<div className="flex mt-1">*/}
          {/*  {[...Array(5)].map((_, i) => (*/}
          {/*    <svg*/}
          {/*      key={i}*/}
          {/*      className={`h-5 w-5 ${*/}
          {/*        i < rating ? "text-yellow-500" : "text-gray-300"*/}
          {/*      }`}*/}
          {/*      fill="currentColor"*/}
          {/*      viewBox="0 0 20 20"*/}
          {/*      aria-hidden="true"*/}
          {/*    >*/}
          {/*      <path*/}
          {/*        fillRule="evenodd"*/}
          {/*        d="M10 2a.725.725 0 01.673.418l1.882 3.815 4.21.612a.725.725 0 01.402 1.235l-3.046 2.97.719 4.192a.725.725 0 01-1.052.764L10 13.847l-3.786 1.99a.725.725 0 01-1.052-.764l.72-4.192L2.833 8.08a.725.725 0 01.402-1.235l4.209-.611L9.327 2.418A.725.725 0 0110 2z"*/}
          {/*        clipRule="evenodd"*/}
          {/*      />*/}
          {/*    </svg>*/}
          {/*  ))}*/}
          {/*</div>*/}
        </div>
      </div>
      <p className="mt-4 text-gray-600">{review}</p>
    </div>
  );
};

// Review.propTypes = {
//   review: PropTypes.object.isRequired,
// };
