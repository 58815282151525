import { useAuth } from "src/context/AuthContext.jsx";
import { toast } from "react-toastify";
import FirebaseService from "src/api/services/firebase.service.js";
import UserService from "src/api/services/user.service.js";
import { useState } from "react";

const Profile = () => {
  const { user, setUser } = useAuth();
  const [password, setPassword] = useState("");

  const handleUpdatePassword = async () => {
    UserService.updateUserById(user._id, {
      password: password,
    }).then(() => {
      toast.success("Password Updated Successfully");
    });
  };

  const handleFileUpload = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const file = await toast.promise(FirebaseService.uploadFile(formData), {
      pending: "Uploading...",
      success: "Uploaded",
      error: "Error uploading file",
    });
    if (file) {
      UserService.updateUserById(user._id, {
        imageLink: file.url.publicUrl,
      }).then((res) => {
        setUser(res);
      });
    }
  };

  return (
    <div className="overflow-hidden bg-white m-5 shadow sm:rounded-lg text-textColor">
      <div className="my-2 flex justify-between items-center">
        <img
          src={user?.imageLink || "https://source.unsplash.com/random/300×300"}
          alt="Uploaded"
          className="h-24 w-24 rounded-full bg-primary cursor-pointer object-cover mx-4"
        />
        <div className="mr-10 flex items-center">
          <p>{user.status} </p>
          <div
            className={`w-2 h-2 ml-3 shadow-lg rounded-full ${
              user.status === "ACTIVE" ? "bg-green-500" : "bg-red-500"
            }`}
          ></div>
        </div>
      </div>
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          {user.name}
        </h3>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Full name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {user.name}
            </dd>
          </div>

          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Email address</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {user.email}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 ">
            <dt className="text-sm font-medium text-gray-900">
              Update Profile Image
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="file"
                multiple={true}
                className="block w-fit text-sm text-slate-500
        file:mr-4 file:py-2 file:px-4 file:rounded-md
        file:border-0 file:text-sm file:font-semibold
        file:bg-pink-5 file:btn-primary"
                onChange={handleFileUpload}
                accept="image/png,.pdf,image/jpeg"
              ></input>
            </dd>
          </div>

          {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">About</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim
              incididunt cillum culpa consequat. Excepteur qui ipsum aliquip
              consequat sint. Sit id mollit nulla mollit nostrud in ea officia
              proident. Irure nostrud pariatur mollit ad adipisicing
              reprehenderit deserunt qui eu.
            </dd>
          </div> */}
          {/*<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">*/}
          {/*  <dt className="text-sm font-medium leading-6 text-gray-900">*/}
          {/*    Attachments*/}
          {/*  </dt>*/}
          {/*  <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">*/}
          {/*    <ul*/}
          {/*      role="list"*/}
          {/*      className="divide-y divide-gray-100 rounded-md border border-gray-200"*/}
          {/*    >*/}
          {/*      <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">*/}
          {/*        <div className="flex w-0 flex-1 items-center">*/}
          {/*          <AiOutlinePaperClip*/}
          {/*            className="h-5 w-5 flex-shrink-0 text-primary"*/}
          {/*            aria-hidden="true"*/}
          {/*          />*/}
          {/*          <div className="ml-4 flex min-w-0 flex-1 gap-2">*/}
          {/*            <span className="truncate font-medium">*/}
          {/*              project_ADU.pdf*/}
          {/*            </span>*/}
          {/*            <span className="flex-shrink-0 text-gray-400">2.4mb</span>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*        <div className="ml-4 flex-shrink-0">*/}
          {/*          <a*/}
          {/*            href="#"*/}
          {/*            className="font-medium text-secondary hover:text-indigo-500"*/}
          {/*          >*/}
          {/*            Download*/}
          {/*          </a>*/}
          {/*        </div>*/}
          {/*      </li>*/}
          {/*      <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">*/}
          {/*        <div className="flex w-0 flex-1 items-center">*/}
          {/*          <AiOutlinePaperClip*/}
          {/*            className="h-5 w-5 flex-shrink-0 text-primary"*/}
          {/*            aria-hidden="true"*/}
          {/*          />*/}
          {/*          <div className="ml-4 flex min-w-0 flex-1 gap-2">*/}
          {/*            <span className="truncate font-medium">*/}
          {/*              project_Manhaton.pdf*/}
          {/*            </span>*/}
          {/*            <span className="flex-shrink-0 text-gray-400">4.5mb</span>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*        <div className="ml-4 flex-shrink-0">*/}
          {/*          <a*/}
          {/*            href="#"*/}
          {/*            className="font-medium text-secondary hover:text-indigo-500"*/}
          {/*          >*/}
          {/*            Download*/}
          {/*          </a>*/}
          {/*        </div>*/}
          {/*      </li>*/}
          {/*    </ul>*/}
          {/*  </dd>*/}
          {/*</div>*/}
        </dl>
      </div>
      <hr />
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">Update Password</dt>
        <input
          type="password"
          name="password"
          value={password}
          placeholder="*****"
          className="input-primary"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="btn-primary w-fit" onClick={handleUpdatePassword}>
          Update Password
        </button>
      </div>
    </div>
  );
};

export default Profile;
