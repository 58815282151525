import { useFormik } from "formik";
import {
  ContactInitialValues,
  ContactSchema,
} from "src/forms/ValidationSchemas/ContactSchema.js";

export const ContactForm = ({
  initialValues = ContactInitialValues,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues: initialValues ?? ContactInitialValues,
    validationSchema: ContactSchema,
    onSubmit: (values, { resetForm }) => {
      onSubmit(values);
      resetForm();
    },
  });
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        action="#"
        method="POST"
        className="px-6 pb-10 pt-4 min-w-[25rem]  bg-primary/90 shadow-xl backdrop-blur-sm rounded-md"
      >
        <div className="flex justify-center w-full pb-6">
          <h1 className="text-3xl font-semibold text-white">Contact Us</h1>
        </div>

        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
          <div className="grid grid-cols-1 gap-x-8 gap-y-3 sm:grid-cols-2">
            <div>
              <label
                htmlFor="first_name"
                className="block text-sm font-semibold leading-6 text-white"
              >
                First name
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  autoComplete="given-name"
                  className="block w-full input-primary"
                />
                {formik.errors.first_name ? (
                  <p className="text-red-500 text-xs mt-1">
                    {formik.errors.first_name}
                  </p>
                ) : null}
              </div>
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block text-sm font-semibold leading-6 text-white"
              >
                Last name
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  autoComplete="family-name"
                  className="block w-full input-primary"
                />
                {formik.errors.last_name ? (
                  <p className="text-red-500 text-xs mt-1">
                    {formik.errors.last_name}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-semibold leading-6 text-white"
              >
                Email
              </label>
              <div className="mt-2.5">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  autoComplete="email"
                  className="block w-full input-primary"
                />
                {formik.errors.email ? (
                  <p className="text-red-500 text-xs mt-1">
                    {formik.errors.email}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="phone_number"
                className="block text-sm font-semibold leading-6 text-white"
              >
                Phone number
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  autoComplete="tel"
                  value={formik.values.phone_number}
                  onChange={formik.handleChange}
                  className="block w-full input-primary"
                />
                {formik.errors.phone_number ? (
                  <p className="text-red-500 text-xs mt-1">
                    {formik.errors.phone_number}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-semibold leading-6 text-white"
              >
                Message
              </label>
              <div className="mt-2.5">
                <textarea
                  name="message"
                  id="message"
                  rows={4}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  className="block w-full input-primary"
                />
                {formik.errors.message ? (
                  <p className="text-red-500 text-xs mt-1">
                    {formik.errors.message}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="mt-8 flex justify-end">
            <button type="submit" className="btn-primary">
              Send message
            </button>
          </div>
          <hr className="my-4" />
          <div className="flex justify-center">
            <a className="text-white" href="tel:(888) 341-6169">
              Call us at (888) 341-6169
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};
