import { ADU_DEFINITION, HOW_WE_HELP } from "src/constants/constants.jsx";
import { useDataContext } from "src/context/DataContext.jsx";
import { useEffect, useRef } from "react";

const Intro = () => {
  const { content } = useDataContext();
  const contentIntroDescriptionRef = useRef(null);
  const contentHelpDescriptionRef = useRef(null);

  useEffect(() => {
    contentIntroDescriptionRef &&
      (contentIntroDescriptionRef.current.innerHTML =
        content.intro?.description || ADU_DEFINITION);
    contentHelpDescriptionRef &&
      (contentHelpDescriptionRef.current.innerHTML =
        content.help?.description || HOW_WE_HELP);
  }, [content]);

  return (
    <section>
      <section>
        <div className="intro-image-wrapper px-5 gap-20 min-h-[40vh] sm:min-h-[80vh] max-w-7xl mx-auto">
          <div className="w-full bg-cover text-center drop-shadow-2xl p-5 text-white flex flex-col justify-center gap-5 mx-2 mt-52 mb-20 sm:mb-0">
            <h1 className="heading-primary text-white text-[2rem]">
              {content.intro?.title || "Accessories Dwelling Units"}
            </h1>
            <p
              className="text-[1.15rem] shadow-text"
              ref={contentIntroDescriptionRef}
            ></p>
          </div>
        </div>
      </section>

      <section className="intro-image-wrapper max-w-7xl mx-auto">
        <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-20 bg-white/70 shadow-xl rounded-[5px] mx-2">
          <div className="w-full h-full flex justify-center">
            <img
              src="/assets/gifs/ADU.gif"
              alt="ADU Image"
              className="w-full h-full object-cover rounded-l-[5px]"
            />
          </div>
          <div className="w-full opacity-100 text-center p-5 text-textColor flex flex-col justify-center gap-5">
            <h1 className="heading-primary">
              {content.help?.title || "How can we help?"}
            </h1>
            <p className="shadow-text" ref={contentHelpDescriptionRef}></p>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Intro;
