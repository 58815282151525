import httpCommon from "src/api/http-common.js";
import { handleResponse } from "src/utils/helpers/actions.js";

const getFiles = (projectId) => {
  return handleResponse(httpCommon.get(`/projectfile/${projectId}`));
};

const getPublicFiles = (projectId) => {
  return handleResponse(httpCommon.get(`/projectfile/public/${projectId}`));
};

const updateProjectFile = (fileId, fileData) => {
  return handleResponse(httpCommon.patch(`/projectfile/${fileId}`, fileData));
};

const deleteFile = (fileId) => {
  return handleResponse(httpCommon.delete(`/projectfile/${fileId}`));
};

const addProjectFile = (fileData) => {
  return handleResponse(httpCommon.post(`/projectfile`, fileData));
};

const deleteProjectFiles = (projectId) => {
  return handleResponse(httpCommon.delete(`/projectfile/bulk/${projectId}`));
};

const ProjectFileService = {
  getFiles,
  addProjectFile,
  updateProjectFile,
  getPublicFiles,
  deleteProjectFiles,
  deleteFile,
};

export default ProjectFileService;
