import { useDataContext } from "src/context/DataContext.jsx";
import { Files } from "src/components/ProjectDetails/index.jsx";
import { useEffect, useState } from "react";
import ProjectFileService from "src/api/services/projectFile.service.js";
import { RiLoader2Fill } from "react-icons/ri";

export const ProjectFiles = () => {
  const { files, setFiles } = useDataContext();
  const { projects } = useDataContext();
  const [loading, setLoading] = useState(false);

  const fetchFiles = (id) => {
    setLoading(true);
    ProjectFileService.getFiles(id)
      .then((res) => {
        setFiles(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (projects?.length > 0 && !files?.length) {
      fetchFiles(projects[0]._id);
    }
  }, [projects]);
  return (
    <div className="projectDetails-wrapper flex flex-col gap-2 relative">
      {loading ? (
        <div className="mt-8 w-full h-[40vh] grid place-items-center">
          <RiLoader2Fill />
        </div>
      ) : (
        <Files files={files} />
      )}
    </div>
  );
};
