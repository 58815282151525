// DataContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import ContentService from "src/api/services/content.service.js";

const DataContext = createContext();

export const useDataContext = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [files, setFiles] = useState([]);

  const [publicProjects, setPublicProjects] = useState([]);
  const [content, setContent] = useState({});

  const cleanUpData = () => {
    setUsers([]);
    setProjects([]);
    setPublicProjects([]);
  };

  useEffect(() => {
    ContentService.getContent().then((res) => {
      setContent(res);
    });
  }, []);

  const contextValue = {
    users,
    setUsers,
    projects,
    setProjects,
    cleanUpData,
    publicProjects,
    setPublicProjects,
    content,
    setContent,
    files,
    setFiles,
  };

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
};
