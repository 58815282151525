import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import { useEffect, useRef } from "react";

const MyPhotoSphereViewer = ({ imageUrl, panoRef }) => {
  const style = {
    width: "100%",
    height: "100%",
  };
  return (
    <div style={style}>
      <ReactPhotoSphereViewer
        ref={panoRef}
        src={imageUrl}
        height={"100%"}
        width={"100%"}
        keyboardActions={false}
      />
    </div>
  );
};

export default MyPhotoSphereViewer;
