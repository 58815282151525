import PropTypes from "prop-types";

const ServiceCategory = ({ category }) => {
  return (
    <div className="serviceCategory-wrapper">
      <h1 className="text-xl text-textColor text-center font-bold">
        {category?.title}
      </h1>
      <div className="services-container mt-5 flex flex-col gap-2">
        {category?.subServices?.map((service, index) => (
          <p
            // className="hover:underline decoration-secondary cursor-pointer text-1xl text-textColor text-center hover:text-primary max-w-[20rem]"
            className="decoration-secondary text-1xl text-textColor text-center max-w-[20rem]"
            key={index}
          >
            {service}
          </p>
        ))}
      </div>
    </div>
  );
};

export default ServiceCategory;

ServiceCategory.propTypes = {
  category: PropTypes.shape({
    title: PropTypes.string,
    services: PropTypes.arrayOf(PropTypes.string),
  }),
};
