import Card from "src/components/Cards/CategoryCard.jsx";

const Categories = () => {
  const data = [
    {
      imageSrc: "/assets/images/ADUT1.jpg",
      title: "Detached ADUs",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      imageSrc: "/assets/images/ADUT2.jpg",
      title: "Internal ADUs",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      imageSrc: "/assets/images/ADUT3.jpg",
      title: "Garage Conversion ADUs",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      imageSrc: "/assets/images/ADUT4.jpg",
      title: "Attached ADUs",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  return (
    <section className="max-w-7xl mx-auto w-full">
      <div className="categories-wrapper flex flex-col gap-5 py-5  bg-white/70 shadow-xl rounded-[5px] mx-2">
        <h1 className="heading-primary ">Types of ADUs</h1>
        <hr />
        <div className="text-primary grid grid-cols-2 sm:grid-cols-4 gap-y-5">
          {data.map((item, index) => (
            <Card
              description={item.description}
              imageSrc={item.imageSrc}
              title={item.title}
              key={index}
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Categories;
