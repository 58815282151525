import { AiFillFolder, AiFillFolderOpen } from "react-icons/ai";

const Project = ({ project }) => {
  return (
    <>
      <div className="project-wrapper group">
        <hr className="border-textColor group-hover:border-none opacity-10"></hr>
        <div className="grid grid-cols-7 text-textColor hover:bg-gray-100 hover:shadow-md w-full p-2 hover:rounded-[5px] hover:cursor-pointer border-black group-hover:border-secondary group-hover:border-[1px]">
          <div className="flex gap-5 items-center col-span-6">
            <AiFillFolder className="icon text-2xl group-hover:hidden text-secondary" />
            <AiFillFolderOpen className="icon text-2xl hidden group-hover:block" />
            <div className="name">{project.name}</div>
          </div>
          <div className="date col-span-1">{project.date}</div>
        </div>
        <hr className="border-textColor group-hover:border-none opacity-10"></hr>
      </div>
    </>
  );
};

export default Project;
