import axios from "axios";
import { getCookie } from "../utils/helpers/cookies";

export default axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: "Bearer " + getCookie("access_token") || null,
  },
});
