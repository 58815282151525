import Table from "src/components/Table/Table.jsx";
import { PROJECTS_TABLE_COLUMNS } from "src/constants/constants.jsx";
import { useEffect, useRef, useState } from "react";
import Modal from "src/components/Modal/Modal.jsx";
import { IoMdAdd } from "react-icons/io";
import ProjectService from "src/api/services/project.service.js";
import { useAuth } from "src/context/AuthContext";
import {
  AiFillDelete,
  AiFillEdit,
  AiOutlineArrowDown,
  AiOutlineArrowUp,
} from "react-icons/ai";
import { ProjectForm } from "src/forms/projectForm";
import { useNavigate } from "react-router-dom";
import { useDataContext } from "src/context/DataContext";
import { diffObj } from "src/utils/helpers/actions.js";
import { toast } from "react-toastify";
import ProjectFileService from "src/api/services/projectFile.service.js";
import { Toggle } from "src/components/Switch/Switch.jsx";

export function Projects() {
  const { projects, setProjects } = useDataContext();
  const createModalRef = useRef(null);
  const updateModalRef = useRef(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState(null);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [isSort, setIsSort] = useState(false);
  const [actions, setActions] = useState([]);

  const openModal = (ref) => {
    ref.current.showModal();
  };

  const closeDialog = (ref) => {
    ref.current.close();
  };

  const handleDelete = (id) => {
    ProjectService.deleteProject(id).then(() => {
      toast.success("Project deleted successfully");
      setProjects(projects.filter((project) => project._id !== id));
      ProjectFileService.deleteProjectFiles(id);
    });
  };

  const addProject = (values) => {
    closeDialog(createModalRef);
    ProjectService.addProject({
      ...values,
      phases: {},
      user_id: user._id,
    }).then((res) => {
      toast.success("Project added successfully");
      setProjects((projects) => [...projects, res]);
    });
  };

  const updateProject = (values) => {
    closeDialog(updateModalRef);
    ProjectService.updateProjectById(
      selectedProject._id,
      diffObj(values, selectedProject),
    ).then((res) => {
      toast.success("Project updated successfully");
      setProjects((projects) =>
        projects.map((project) => (project._id === res._id ? res : project)),
      );
    });
  };

  const bulkUpdateProjects = (values) => {
    const updatedIndexes = values.map(({ _id, index }) => ({ id: _id, index }));
    ProjectService.updateProjectsBulk(updatedIndexes).then((res) => {
      toast.success("Projects updated successfully");
      setIsSort(false);
      setProjects((projects) =>
        projects.map((project) => {
          const index = res.findIndex((p) => p._id === project._id);
          if (index !== -1) {
            return res[index];
          }
          return project;
        }),
      );
    });
  };
  const updateProjectIndex = (currIndex, prevIndex) => {
    setSortedProjects((prev) => {
      const newArray = [...prev];
      const prevId = newArray.find((p) => p.index === prevIndex)?._id;
      if (prevId === undefined) return newArray;
      const currId = newArray.find((p) => p.index === currIndex)?._id;
      newArray.map((p) => {
        if (p._id === currId) {
          p.index = prevIndex;
        }
        if (p._id === prevId) {
          p.index = currIndex;
        }
      });
      newArray.sort((a, b) => a.index - b.index);
      return newArray;
    });
  };

  useEffect(() => {
    if (projects.length === 0) return;
    setSortedProjects(
      projects
        .sort((a, b) => a.index - b.index)
        .map((p) => ({ ...p, assign_user: p.assign_user.name })),
    );
  }, [projects]);

  useEffect(() => {
    if (isSort) {
      setActions([
        {
          type: "sort-up",
          icon: <AiOutlineArrowUp className="fill-[blue]" />,
          onClick: (e) => {
            updateProjectIndex(e.index, e.index - 1);
          },
        },
        {
          type: "sort-down",
          icon: <AiOutlineArrowDown className="fill-[blue]" />,
          onClick: (e) => {
            updateProjectIndex(e.index, e.index + 1);
          },
        },
      ]);
    } else {
      setActions([
        {
          type: "delete",
          icon: <AiFillDelete className="fill-[red]" />,
          onClick: ({ _id }) => {
            handleDelete(_id);
          },
        },
        {
          type: "update",
          icon: <AiFillEdit className="fill-[blue]" />,
          onClick: (e) => {
            setSelectedProject(e);
            openModal(updateModalRef);
          },
        },
      ]);
    }
  }, [isSort]);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center px-4 sm:px-6 lg:px-8">
        <Toggle label={"Sort"} enabled={isSort} setEnabled={setIsSort} />
        {isSort ? (
          <button
            className={"btn-primary"}
            onClick={() => bulkUpdateProjects(sortedProjects)}
          >
            Save Sort
          </button>
        ) : (
          <button
            onClick={() => openModal(createModalRef)}
            className="btn-primary w-fit flex justify-center items-center gap-1 p-2 rounded-md"
          >
            <IoMdAdd className="fill-white" />
            Add Project
          </button>
        )}
      </div>
      <Table
        rowClickable={({ _id }) => navigate(_id)}
        columns={PROJECTS_TABLE_COLUMNS}
        data={sortedProjects}
        actions={actions}
        noDataMessage="Add projects to show"
      />
      <Modal modalRef={createModalRef} className="h-fit">
        <ProjectForm onSubmit={addProject} type={"create"} />
      </Modal>
      <Modal modalRef={updateModalRef} className="h-fit">
        {selectedProject && (
          <ProjectForm
            onSubmit={updateProject}
            initialValues={selectedProject}
            type={"update"}
          />
        )}
      </Modal>
    </div>
  );
}
