import { ContactForm } from "src/forms/contactForm.jsx";
import ContactService from "src/api/services/contact.service.js";
import { toast } from "react-toastify";
export const Contact = () => {
  const handleSubmit = (data) => {
    ContactService.addMessage(data).then(() => {
      toast.success("Message sent successfully");
    });
  };

  return (
    <section
      id="contact"
      className="max-w-7xl flex justify-end p-10 sm:-mt-[20rem]"
    >
      <ContactForm onSubmit={handleSubmit} />
    </section>
  );
};
