import Table from "src/components/Table/Table.jsx";
import { USERS_TABLE_COLUMNS } from "src/constants/constants.jsx";
import UserServices from "src/api/services/user.service.js";
import { useRef, useState } from "react";
import { useDataContext } from "src/context/DataContext";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { toast } from "react-toastify";
import { IoMdAdd } from "react-icons/io";
import Modal from "src/components/Modal/Modal.jsx";
import { AddUserForm } from "src/forms/addUserForm.jsx";
import { UpdateUserForm } from "src/forms/updateUserForm.jsx";
import { diffObj } from "src/utils/helpers/actions.js";

const Users = () => {
  const { users, setUsers } = useDataContext();
  const createModalRef = useRef(null);
  const updateModalRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleDelete = (id) => {
    UserServices.deleteUserById(id).then(() => {
      toast.success("User deleted successfully");
      setUsers(users.filter((user) => user._id !== id));
    });
  };
  const openModal = (ref) => {
    ref.current.showModal();
  };

  const closeDialog = (ref) => {
    ref.current.close();
  };

  const addUser = (values) => {
    closeDialog(createModalRef);
    UserServices.addUser({
      ...values,
      status: "ACTIVE",
    }).then((res) => {
      toast.success("User added successfully");
      setUsers((users) => [...users, res]);
    });
  };

  const updateUser = (values) => {
    closeDialog(updateModalRef);
    const user = diffObj(values, selectedUser);
    UserServices.updateUserById(values._id, user).then((res) => {
      toast.success("User updated successfully");
      setUsers((users) => {
        const index = users.findIndex((user) => user._id === res._id);
        users[index] = res;
        return [...users];
      });
    });
  };

  return (
    <div className="flex flex-col">
      <button
        onClick={() => openModal(createModalRef)}
        className="btn-primary w-fit self-end mr-10 flex justify-center items-center gap-1 p-2 rounded-md"
      >
        <IoMdAdd className="fill-white" />
        Add User
      </button>

      <Table
        columns={USERS_TABLE_COLUMNS}
        data={users}
        actions={[
          {
            type: "delete",
            icon: <AiFillDelete className="fill-[red]" />,
            onClick: ({ _id }) => {
              handleDelete(_id);
            },
          },
          {
            type: "update",
            icon: <AiFillEdit className="fill-[blue]" />,
            onClick: (e) => {
              setSelectedUser(e);
              openModal(updateModalRef);
            },
          },
        ]}
      />
      <Modal modalRef={createModalRef} className="h-fit">
        <AddUserForm onSubmit={addUser} type={"create"} />
      </Modal>
      <Modal modalRef={updateModalRef} className="h-fit">
        {selectedUser && (
          <UpdateUserForm onSubmit={updateUser} initialValues={selectedUser} />
        )}
      </Modal>
    </div>
  );
};

export default Users;
