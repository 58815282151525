import httpCommon from "src/api/http-common.js";
import { handleResponse } from "src/utils/helpers/actions.js";

const addMessage = (data) => {
  return handleResponse(httpCommon.post("/contactus", data));
};

const getMessages = () => {
  return handleResponse(httpCommon.get("/contactus"));
};

const deleteMessage = (id) => {
  return handleResponse(httpCommon.delete(`/contactus/${id}`));
};

const ContactService = {
  addMessage,
  getMessages,
  deleteMessage,
};
export default ContactService;
