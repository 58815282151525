const PinnedProject = ({ name, index, title }) => {
  return (
    <li className="col-span-1 flex rounded-md shadow-sm max-w-[20rem]">
      <div
        className={`flex w-16 flex-shrink-0 items-center justify-center ${
          index % 2 == 0 ? " bg-primary" : " bg-secondary"
        } rounded-l-md text-sm font-medium text-white`}
      >
        CDG
      </div>
      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray bg-gray-50">
        <div className="flex-1 truncate px-4 py-2 text-sm">
          <span className="font-medium text-gray-900 hover:text-gray-600">
            {name}
          </span>
          <p className="text-gray-500 whitespace-normal">{title}</p>
        </div>
      </div>
    </li>
  );
};

export default PinnedProject;
