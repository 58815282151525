import { useEffect, useRef } from "react";
import ImageSlider from "src/components/Slider/ImageSlider.jsx";
import Modal from "src/components/Modal/Modal.jsx";
import PropTypes from "prop-types";

const Card = ({ item, ...rest }) => {
  const { images, name, title, description } = item;

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    return () => {
      document.removeEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
    };
  }, []);

  return (
    <>
      <div
        className="hover:transform categoryCard-wrapper flex flex-col gap-2 cursor-pointer drop-shadow-md mt-2 bg-primary rounded-[10%_0%_10%_0] max-w-[18rem] "
        // onClick={handleClick}
        {...rest}
      >
        <div className="flex justify-center items-center">
          <img
            src={
              images && images[0] ? images[0] : "/assets/images/placeholder.jpg"
            }
            alt="category image"
            className="w-full rounded-tl-[10px] h-[12rem]"
            loading={"lazy"}
            placeholder={"blur"}
          />
        </div>
        <div className="p-5 flex flex-col gap-2 text-white">
          <h2 className=" font-bold text-center">{name}</h2>
          <p className="text-center">{title || name}</p>
        </div>
      </div>
    </>
  );
};
export default Card;

Card.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  description: PropTypes.string,
};
