import { toast } from "react-toastify";
import { signOutUser } from "src/utils/helpers/cookies.js";

export default async function downloadFile(
  imageSrc,
  nameOfDownload = "my-image.png",
) {
  fetch(imageSrc)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = nameOfDownload; // Set the desired filename

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      toast.error("Error downloading file:", error);
    });
}

export const handleResponse = (promise) => {
  return promise
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      let { response } = error;
      if (!response) {
        toast.error(error.message);
        return;
      }
      let {
        data: { message },
      } = response;
      if (message === undefined) {
        message = "Something went wrong";
      }
      if (Array.isArray(message)) {
        message = message[0];
      }
      if (message === "Unauthorized") {
        toast.error("Session expired, please login again", {
          onClose: () => {
            signOutUser();
            window.location.href = "/signin";
          },
        });
        return;
      }
      toast.error(message);
      throw error; // Re-throw the error for the caller to handle
    });
};

export const transformDate = (date) => {
  return format(new Date(date).toLocaleDateString("en-US"));
};

const format = (date) => {
  const dateArray = date.split("/");
  dateArray[0] = dateArray[0].length === 1 ? `0${dateArray[0]}` : dateArray[0];
  dateArray[1] = dateArray[1].length === 1 ? `0${dateArray[1]}` : dateArray[1];
  return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
};

export const extractNameFromUrl = (url) => {
  return url.split("/").pop();
};

export const diffObj = (obj1, obj2) => {
  let diff = {};
  //find keys which are same but values are different
  for (let key in obj1) {
    if (obj1[key] !== obj2[key]) diff[key] = obj1[key];
  }
  return diff;
};

export function getCentroid(coords) {
  if (!coords.length) {
    return null;
  }

  let totalX = 0;
  let totalY = 0;

  for (let i = 0; i < coords.length; i++) {
    totalX += parseFloat(coords[i].x);
    totalY += parseFloat(coords[i].y);
  }
  return {
    x: totalX / coords.length,
    y: totalY / coords.length,
  };
}

// Example
