import { useFormik } from "formik";
import {
  UserInitialValues,
  UserSchema,
} from "src/forms/ValidationSchemas/UserSchema.js";
import { FormField } from "src/components/FormField/FormField.jsx";

export const AddUserForm = ({
  initialValues = UserInitialValues,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues: initialValues ?? UserInitialValues,
    validationSchema: UserSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        method={"POST"}
        className="mx-auto p-4 w-full min-w-[20rem]"
      >
        <FormField
          label="Name"
          type="text"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.errors.name}
        />
        <FormField
          label="Email"
          type="text"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email}
        />

        <div className="my-2">
          <label
            htmlFor="role"
            className="block text-sm font-medium leading-6 text-textColor"
          >
            Role:
          </label>
          <div className="my-1">
            <select
              onChange={formik.handleChange}
              value={formik.values.role}
              name="role"
              className="block px-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
            >
              <option value="" disabled hidden>
                Select Role
              </option>
              <option value="ADMIN">ADMIN</option>
              <option value="CLIENT">CLIENT</option>
            </select>
            <p className="text-red-500 text-xs">{formik.errors.role}</p>
          </div>
        </div>
        <FormField
          label="Password"
          type="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.errors.password}
        />
        <div className="my-2">
          <button
            type="submit"
            className="flex w-full justify-center rounded-md btn-primary px-3 m-0 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
          >
            Add User
          </button>
        </div>
      </form>
    </div>
  );
};
