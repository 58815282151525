import { MenuLayout } from "src/layout/MenuLayout/MenuLayout.jsx";
import { CLIENT_PORTAL_ROUTES } from "src/constants/constants.jsx";
import { useEffect } from "react";
import ProjectService from "src/api/services/project.service.js";
import { useDataContext } from "src/context/DataContext.jsx";
import { useAuth } from "src/context/AuthContext.jsx";

const ClientPortal = () => {
  const { user } = useAuth();
  const { projects, setProjects } = useDataContext();

  useEffect(() => {
    if (projects.length === 0 && user) {
      ProjectService.getProjectsByUserId(user._id).then((res) => {
        setProjects(res);
      });
    }
  }, [user]);

  return (
    <div className="clientPortal-wrapper">
      <MenuLayout menuItems={CLIENT_PORTAL_ROUTES} />
    </div>
  );
};

export default ClientPortal;
