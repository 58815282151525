import { useRef } from "react";
import Modal from "src/components/Modal/Modal.jsx";
import ProjectService from "src/api/services/project.service.js";
import { toast } from "react-toastify";
import { useDataContext } from "src/context/DataContext.jsx";

const InputReview = () => {
  const createModalRef = useRef(null);
  const reviewRef = useRef(null);
  const { projects, setProjects } = useDataContext();
  const { _id } = projects[0] || {};
  const openModal = (ref) => {
    ref.current.showModal();
  };

  const closeDialog = (ref) => {
    ref.current.close();
  };

  const handleSubmitReview = () => {
    closeDialog(createModalRef);
    ProjectService.updateProjectById(_id, {
      review: [reviewRef.current.value],
    }).then((res) => {
      toast.success("Project updated successfully");
      setProjects((projects) =>
        projects.map((project) => (project._id === res._id ? res : project)),
      );
    });
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <button
        onClick={() => openModal(createModalRef)}
        className="btn-primary w-fit"
      >
        Give Review
      </button>
      <Modal modalRef={createModalRef} className="h-fit">
        <div className="p-10 pb-5 flex flex-col items-end gap-5">
          <div className="flex flex-col">
            <label>Review</label>
            <textarea
              ref={reviewRef}
              className="border-[1px] p-5"
              placeholder="Write Review Here"
            ></textarea>
          </div>
          <button onClick={handleSubmitReview} className="btn-primary">
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default InputReview;
