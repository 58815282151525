import Map from "src/pages/Home/Portfolio/Map/Map.jsx";
import Projects from "src/pages/Home/Portfolio/Projects/Projects.jsx";
import { Fragment, useEffect, useRef, useState } from "react";
import ProjectService from "src/api/services/project.service.js";
import { useDataContext } from "src/context/DataContext.jsx";
import ProjectFileService from "src/api/services/projectFile.service.js";
import Modal from "src/components/Modal/Modal.jsx";
import ImageSlider from "src/components/Slider/ImageSlider.jsx";
import FilterBox from "src/pages/Home/Portfolio/FilterBox/FilterBox.jsx";

const Portfolio = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const { publicProjects, setPublicProjects } = useDataContext();
  const [publicProjectsImages, setPublicProjectsImages] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const modalRef = useRef(null);

  const fetchPublicProjects = () => {
    ProjectService.getPublicProjects().then((res) => {
      setPublicProjects(res);
    });
  };

  const handleShowModal = () => {
    modalRef.current.showModal();
  };

  const fetchImages = (project) => {
    ProjectFileService.getPublicFiles(project._id).then((res) => {
      setPublicProjectsImages((prev) => [
        ...prev,
        {
          ...project,
          images: res
            .filter(
              (file) =>
                (file.type === "image/jpeg" || file.type === "image/png") &&
                file.isPublished,
            )
            .map((item) => item.link),
        },
      ]);
    });
  };

  useEffect(() => {
    if (publicProjects.length != 0) {
      publicProjects.forEach((project) => {
        fetchImages(project);
      });
    }
  }, [publicProjects]);

  useEffect(() => {
    if (publicProjects.length === 0) {
      fetchPublicProjects();
    }
  }, []);

  return (
    <>
      <div className="portfolio-wrapper flex flex-col gap-5 bg-white">
        <h1 className="heading-primary mt-5">Projects</h1>
        <hr />
        <FilterBox
          publicProjects={publicProjectsImages}
          setFilteredProjects={setFilteredProjects}
        />
        <Map
          projects={filteredProjects}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          handleShowModal={handleShowModal}
        />
      </div>
      <div className="mt-10">
        {filteredProjects?.length !== 0 && (
          <Projects
            projects={filteredProjects}
            setSelectedProject={setSelectedProject}
            handleShowModal={handleShowModal}
          />
        )}
      </div>
      <Modal
        modalRef={modalRef}
        className={"border-primary border-4 h-fit max-h-[80vh] "}
      >
        {selectedProject && <ImageSlider item={selectedProject} />}
      </Modal>
    </>
  );
};
export default Portfolio;
