import { useFormik } from "formik";
import { FormField, TextAreaField } from "../components/FormField/FormField";
import {
  ProjectInitialValues,
  ProjectSchema,
} from "./ValidationSchemas/ProjectSchema";
import { useDataContext } from "src/context/DataContext.jsx";
import { useEffect, useRef, useState } from "react";
import UserServices from "src/api/services/user.service.js";
import Modal from "src/components/Modal/Modal.jsx";
import MapDirectory from "src/components/MapDirectory/MapDirectory.jsx";

export const ProjectForm = ({
  initialValues = ProjectInitialValues,
  onSubmit,
  type,
}) => {
  const { users, projects } = useDataContext();
  const modalRef = useRef();
  const [address, setAddress] = useState("");
  const formik = useFormik({
    validationSchema: ProjectSchema,
    initialValues: initialValues,
    onSubmit: (values, { resetForm }) => {
      resetForm();
      onSubmit({
        ...values,
        latitude: values.latitude.toString(),
        longitude: values.longitude.toString(),
        review: [],
        index: projects.length,
      });
    },
  });

  const openModal = () => {
    modalRef.current.showModal();
  };

  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  return (
    <div className="max-h-[80vh] overflow-y-auto">
      <form
        onSubmit={formik.handleSubmit}
        method={"POST"}
        className=" mx-auto p-4 w-full"
      >
        <div className="flex justify-between gap-2">
          <FormField
            label="Project Name"
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
            className={"w-1/2"}
          />
          <FormField
            label="Project Title"
            type="text"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.errors.title}
            className={"w-1/2"}
          />
        </div>
        <div className="flex justify-between gap-2">
          <div className="my-2 w-1/2">
            <label
              htmlFor="assignTo"
              className="block text-sm font-medium leading-6 text-textColor"
            >
              Assign To:
            </label>
            <div className="my-1">
              <select
                onChange={formik.handleChange}
                value={formik.values.assign_to}
                name="assign_to"
                className="block px-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
              >
                <option value="" disabled hidden>
                  Select User
                </option>
                {users?.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.name}
                  </option>
                ))}
              </select>
              <p className="text-red-500 text-xs">{formik.errors.assign_to}</p>
            </div>
          </div>
          <div className="my-2 w-1/2">
            <label
              htmlFor="assignTo"
              className="block text-sm font-medium leading-6 text-textColor"
            >
              Project Type:
            </label>
            <div className="my-1">
              <select
                onChange={formik.handleChange}
                value={formik.values.type}
                name="type"
                className="block px-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
              >
                <option value="" disabled hidden>
                  Select Type
                </option>
                <option value="ADU">ADU's</option>
                <option value="POOL">Pools</option>
                <option value="BARNDOMINIUMS">Barndominiums</option>
                <option value="REMODELS">Remodels/Additions</option>
              </select>
              <p className="text-red-500 text-xs">{formik.errors.assign_to}</p>
            </div>
          </div>
        </div>
        <TextAreaField
          label="Description"
          type="text"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.errors.description}
        />
        {/*<div className="flex justify-between gap-2">*/}
        {/*  <FormField*/}
        {/*    label="Start Date"*/}
        {/*    type="date"*/}
        {/*    name="start_date"*/}
        {/*    value={formik.values.start_date}*/}
        {/*    onChange={formik.handleChange}*/}
        {/*    error={formik.errors.start_date}*/}
        {/*    className="w-1/2"*/}
        {/*  />*/}

        {/*  <FormField*/}
        {/*    label="Estimated End Date"*/}
        {/*    type="date"*/}
        {/*    name="estimated_end_date"*/}
        {/*    value={formik.values.estimated_end_date}*/}
        {/*    onChange={formik.handleChange}*/}
        {/*    error={formik.errors.estimated_end_date}*/}
        {/*    className="w-1/2"*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="my-2">
          <label className="block text-sm font-medium leading-6 text-textColor">
            Select Location
          </label>
          <button
            onClick={openModal}
            type="button"
            className="bg-primary text-white px-2 py-1 rounded-md mt-2 w-full"
          >
            Open Map
          </button>
          <div className="flex justify-between gap-2">
            <FormField
              label="Latitude"
              type="text"
              name="name"
              value={formik.values.latitude}
              onChange={formik.handleChange}
              error={formik.errors.name}
              className={"w-1/2"}
            />
            <FormField
              label="Longitude"
              type="text"
              name="title"
              value={formik.values.longitude}
              onChange={formik.handleChange}
              error={formik.errors.title}
              className={"w-1/2"}
            />
          </div>
        </div>

        <div className="flex justify-between gap-2">
          <div className="my-2 w-1/2">
            <label
              htmlFor="status"
              className="block text-sm font-medium leading-6 text-textColor"
            >
              Status
            </label>

            <div className="my-1">
              <select
                onChange={formik.handleChange}
                value={formik.values.status}
                name="status"
                className="block px-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
              >
                <option value="" disabled selected hidden>
                  Select Status
                </option>
                <option value="Field_Survey">Field Survey</option>
                <option value="Digitize_Phase">Digitize Phase</option>
                <option value="Design_Concepts">Design Concepts</option>
                <option value="Construction_Documents">
                  Construction Documents
                </option>
                <option value="City_Submission">City Submission</option>
                <option value="Completed">Completed</option>
              </select>
              <p className="text-red-500 text-xs">{formik.errors.status}</p>
            </div>
          </div>
          <div className="my-2 w-1/2">
            <label
              htmlFor="status"
              className="block text-sm font-medium leading-6 text-textColor"
            >
              Access
            </label>
            <div className="my-1">
              <select
                onChange={formik.handleChange}
                value={formik.values.access}
                name="access"
                className="block px-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 "
              >
                <option value="" selected disabled hidden>
                  Select Access Level
                </option>
                <option value="PRIVATE">Private to user</option>
                <option value="PUBLIC">Public</option>
              </select>
              <p className="text-red-500 text-xs">{formik.errors.access}</p>
            </div>
          </div>
        </div>
        <div className="my-2">
          <button
            type="submit"
            className="flex w-full justify-center rounded-md btn-primary px-3 m-0 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
          >
            {type === "create" ? "Create Project" : "Update Project"}
          </button>
        </div>
      </form>
      <Modal modalRef={modalRef} className="h-fit !absolute top-10 right-10">
        <MapDirectory
          values={{
            latitude: formik.values.latitude,
            longitude: formik.values.longitude,
          }}
          setValues={(e, features) => {
            formik.setValues((prev) => {
              return {
                ...prev,
                latitude: e.latitude,
                longitude: e.longitude,
              };
            });
            setAddress(features[0]?.place_name);
          }}
        />
      </Modal>
    </div>
  );
};
