import * as Yup from "yup";
export const ProjectSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter project name")
    .required("This field is required"),
  description: Yup.string()
    .required("Please enter description")
    .required("This field is required"),
  type: Yup.string()
    .required("Please enter description")
    .required("This field is required"),
  assign_to: Yup.string()
    .required("Please select assign to")
    .required("This field is required"),
  // start_date: Yup.date().required("Please enter start date").required("This field is required"),
  latitude: Yup.string()
    .required("Please enter latitude")
    .required("This field is required"),
  longitude: Yup.string()
    .required("Please enter longitude")
    .required("This field is required"),
  title: Yup.string()
    .required("Please enter title")
    .required("This field is required"),
  status: Yup.string()
    .oneOf([
      "Field_Survey",
      "Digitize_Phase",
      "Design_Concepts",
      "Construction_Documents",
      "City_Submission",
      "Completed",
    ])
    .required("Please select status")
    .required("This field is required"),
  access: Yup.string()
    .required("Please select access")
    .required("This field is required"),
  // estimated_end_date: Yup.date().required("Please enter estimated end date").required("This field is required").when("start_date", (start_date, schema) =>
  // start_date ? schema.min(start_date, "Must be greater than start date") : schema
});

export const ProjectInitialValues = {
  name: "",
  description: "",
  assign_to: "",
  // start_date: "",
  // estimated_end_date: "",
  type: "ADU",
  latitude: "",
  longitude: "",
  status: "",
  access: "",
  title: "",
  start_date: new Date(),
  estimated_end_date: new Date(),
};
