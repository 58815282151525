import { SERVICES } from "src/constants/constants.jsx";
import ServiceCategory from "src/pages/Home/Services/ServiceCategory/ServiceCategory.jsx";
import { useDataContext } from "src/context/DataContext.jsx";

const Services = () => {
  const { content } = useDataContext();
  return (
    <div className="services-wrapper  flex flex-col gap-5 p-5">
      <h1 className="heading-primary">Services</h1>
      <hr />
      <div className="categories flex flex-wrap gap-10 justify-center max-w-7xl mx-auto">
        {content?.services?.map((category, index) => (
          <ServiceCategory category={category} key={index} />
        ))}
      </div>
    </div>
  );
};
export default Services;
