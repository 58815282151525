import { AiOutlineClose } from "react-icons/ai";
import { useRef } from "react";

export default function Modal({
  modalRef,
  children,
  className = "h-fit w-fit",
  clearModal = () => {},
}) {
  const handleClose = () => {
    clearModal();
    modalRef.current.close();
  };

  return (
    <dialog
      ref={modalRef}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          e.preventDefault();
        }
      }}
    >
      <div
        className="fixed inset-0 bg-black/50 pointer-events-none"
        aria-hidden="true"
      />
      <div
        className="fixed inset-0 flex w-screen h-screen items-center justify-center p-4"
        // onClick={handleClose}
      >
        <div
          className={`relative mx-auto overflow-auto md:overflow-hidden rounded bg-white flex justify-center items-center ${className}`}
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className="top-0 right-0 absolute z-[60] w-fit outline-none border-none self-end rounded-full bg-white p-2 m-1 opacity-50 hover:opacity-100"
            onClick={handleClose}
          >
            <AiOutlineClose />
          </button>
          {children}
        </div>
      </div>
    </dialog>
  );
}
