import Project from "src/pages/ClientPortal/Projects/Project/Project.jsx";
import { Link } from "react-router-dom";
import {useEffect, useState} from "react";
import { useAuth } from "src/context/AuthContext";
import ProjectService from "src/api/services/project.service.js";
import {RiLoader2Fill} from "react-icons/ri";
import {useDataContext} from "src/context/DataContext.jsx";

const Projects = () => {
  const [loading, setLoading] = useState(false);
  const {user} = useAuth() ;
  const { projects, setProjects } = useDataContext();
  useEffect(() => {
      if(projects.length === 0 && user) {
        setLoading(true);
        ProjectService.getProjectsByUserId(user._id).then((res) => {
          setProjects(res);
        }).finally(
            ()=>setLoading(false)
        );
      }
  }, [user]);
  return (
    <div className="projects-wrapper flex flex-col gap-5 p-3">
      <div className="container flex flex-col px-3">
        <div className="grid grid-cols-7 w-full text-textColor">
          <p className="col-span-6">Name</p>
          <p className="col-span-1">Last Modified</p>
        </div>
        <div className="projects-container flex flex-col gap-6 mt-6">
          {
            loading ?  <div className="mt-8 w-full h-[40vh] grid place-items-center">
                  <RiLoader2Fill />
                </div>
                :
                projects.length ==0 ? <div className="mt-8 w-full h-[40vh] grid place-items-center">
                    <p>No Projects Found</p>
                    </div>  :
              projects.map((project) => (
            <Link key={project._id} to={`/portal/projects/${project._id}`}>
              <Project key={project.id} project={project} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
